import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

import { API_URL_BFC, STATES } from "../../utils/helpers";

import Notice from "../../components/Notice";
import Input from "../../components/Input";
import Select from "../../components/Select";
import InputFile from "../../components/InputFile";
import Textarea from "../../components/Textarea";
import Radios from "../../components/Radios";
import Checkbox from "../../components/Checkbox";
import Msg from "../../components/Msg";
import Submit from "../../components/Submit";
import Alert from "../../components/Alert";

import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

const Details1 = ({ code, status, cares }) => {
	const [errors, setErrors] = useState([]);
	const [names, setNames] = useState({});
	const [msg, setMsg] = useState({});
	const [alert, setAlert] = useState({
		type: "working",
		text: "",
	});

	useEffect(() => {
		if (code) {
			init(code);
		} else {
			setAlert({});
		}
	}, [code]);

	const init = async (code) => {
		const url = new URL(`${API_URL_BFC}/`);
		const params = {
			f: "signupDetails1Init",
			code,
			x: uuidv4(),
		};
		url.search = new URLSearchParams(params);

		try {
			const response = await fetch(url, {
				method: "GET",
				cache: "no-store",
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setNames({
					...json.charity,
				});
				setAlert({});
			} else {
				setAlert({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setAlert({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	const handleUpdate = (name, value) => {
		if (name === "exempt" || name === "acknowledge") {
			setNames((names) => ({ ...names, [name]: !names[name] }));
		} else {
			setNames((names) => ({ ...names, [name]: value ? value : "" }));
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setErrors([]);
		setMsg({
			type: "working",
			text: "",
		});

		const url = new URL(`${API_URL_BFC}/?f=signupDetails1`);
		let formData = new FormData();
		Object.entries(names).forEach(([key, value]) => {
			if (key === "exempt" || key === "acknowledge") {
				formData.append(key, value ? "1" : "0");
			} else {
				formData.append(key, value);
			}
		});
		formData.append("code", code || "");

		try {
			const response = await fetch(url, {
				method: "POST",
				body: formData,
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setMsg({});
				status(json.id, 1);
			} else {
				setErrors(json.fields);
				setMsg({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setMsg({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	return (
		<>
			{alert.type ? (
				<Alert data={alert} />
			) : (
				<form method="post" action="/" onSubmit={(e) => handleSubmit(e)}>
					<p>
						Yes, our organization would like to participate in the Birdies for
						Children program conducted by The Classic in The Palm Beaches.
					</p>

					<Notice
						data={{
							type: "notice",
							text: `IMPORTANT NOTE: This form requires complete information in order to be submitted. Please have PDF scans of your IRS 501(c)3 letter
            and a completed W-9 form ready to upload. If you wish to have your logo included on your donation page, please have a JPG or PNG ready as well.`,
						}}
					/>

					<div className="two">
						<div>
							<Input
								type="text"
								label="Charity Name"
								req={true}
								name="name"
								value={names.name}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
						<div>
							<Input
								type="text"
								label="EIN Number"
								req={true}
								name="ein"
								value={names.ein}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
					</div>

					<p className="line" />

					<h3>Address (where to send checks)</h3>

					<div className="two">
						<div>
							<Input
								type="text"
								label="Address"
								req={true}
								name="address"
								value={names.address}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
						<div>
							<Input
								type="text"
								label="Address 2"
								req={false}
								name="address2"
								value={names.address2}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
					</div>

					<div className="three">
						<div>
							<Input
								type="text"
								label="City"
								req={true}
								name="city"
								value={names.city}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
						<div className="small">
							<Select
								label="State"
								req={true}
								name="st"
								value={names.st}
								data={STATES}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
						<div className="small">
							<Input
								type="text"
								label="Zip"
								req={true}
								name="zip"
								value={names.zip}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
					</div>

					<p className="line" />

					<div className="three">
						<div className="small">
							<Input
								type="text"
								label="Phone"
								req={true}
								name="phone"
								value={names.phone}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
						<div className="small">
							<Input
								type="text"
								label="Fax"
								req={false}
								name="fax"
								value={names.fax}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
						<div>
							<Input
								type="text"
								label="Web Site"
								req={false}
								name="web"
								value={names.web}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
					</div>

					<p className="line" />

					<div className="two">
						<div>
							<Input
								type="text"
								label="Contact First Name"
								req={true}
								name="cfname"
								value={names.cfname}
								update={handleUpdate}
								errors={errors}
							/>
						</div>

						<div>
							<Input
								type="text"
								label="Contact Last Name"
								req={true}
								name="clname"
								value={names.clname}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
					</div>

					<div className="two">
						<div>
							<Input
								type="text"
								label="Contact Email Address"
								req={true}
								name="email"
								value={names.email}
								update={handleUpdate}
								errors={errors}
							/>
						</div>

						<div>
							<Input
								type="text"
								label="Contact Phone"
								req={true}
								name="cphone"
								value={names.cphone}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
					</div>

					<div className="two">
						<div>
							<Input
								type="text"
								label="Finance Contact First Name"
								req={true}
								name="cfname2"
								value={names.cfname2}
								update={handleUpdate}
								errors={errors}
							/>
						</div>

						<div>
							<Input
								type="text"
								label="Finance Contact Last Name"
								req={true}
								name="clname2"
								value={names.clname2}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
					</div>

					<div className="two">
						<div>
							<Input
								type="text"
								label="Finance Contact Email"
								req={true}
								name="email2"
								value={names.email2}
								update={handleUpdate}
								errors={errors}
							/>
						</div>

						<div>
							<Input
								type="text"
								label="Finance Contact Phone"
								req={true}
								name="cphone2"
								value={names.cphone2}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
					</div>

					<p className="line" />

					<div className="two">
						<div>
							<Textarea
								label="Mission Statement"
								req={true}
								name="mission"
								value={names.mission}
								update={handleUpdate}
								errors={errors}
							/>
						</div>

						<div>
							<Input
								type="text"
								label="Annual Children Supported"
								req={true}
								name="children"
								value={names.children}
								update={handleUpdate}
								errors={errors}
								note="Please enter the number of Children that your organization supports on an annual basis (must be a number)."
							/>
						</div>
					</div>

					<div style={{ marginTop: "20px" }}>
						<Radios
							name="underserved"
							hdg="Does the proposed program serve at least 51% of an underserved population?"
							update={handleUpdate}
							data={[
								{
									value: "Yes",
									label: "Yes",
								},
								{
									value: "No",
									label: "No",
								},
							].map((item) => {
								return {
									value: item.value,
									label: item.label,
									checked: names.underserved === item.value ? true : false,
								};
							})}
						/>
					</div>

					<div style={{ marginTop: "10px" }}>
						<Radios
							name="percentage"
							hdg="Are the percentage of people served by the proposed program at least 51% Black or African American citizens?"
							update={handleUpdate}
							data={[
								{
									value: "Yes",
									label: "Yes",
								},
								{
									value: "No",
									label: "No",
								},
							].map((item) => {
								return {
									value: item.value,
									label: item.label,
									checked: names.percentage === item.value ? true : false,
								};
							})}
						/>
					</div>

					{names.percentage === "No" && (
						<div className="two" style={{ marginTop: "10px" }}>
							<Select
								label="If no, what group(s) comprise at least 51% of the people served by the program?"
								req={true}
								name="groups"
								value={names.groups}
								data={[
									{
										value: "",
										name: "--",
									},
									{
										value: "American Indian or Alaska Native",
										name: "American Indian or Alaska Native",
									},
									{
										value: "Asian",
										name: "Asian",
									},
									{
										value: "Hispanic, Latino, or Spanish",
										name: "Hispanic, Latino, or Spanish",
									},
									{
										value: "Native Hawaiian or Pacific Islander",
										name: "Native Hawaiian or Pacific Islander",
									},
									{
										value: "White",
										name: "White",
									},
								]}
								update={handleUpdate}
								errors={errors}
							/>

							<div className="empty" />
						</div>
					)}

					<p className="line" />

					<div className={names.exempt ? "hidden" : "one"}>
						<InputFile
							label="IRS 501(c)3 Document"
							req={true}
							name="irs501c3"
							value={names.irs501c3}
							update={handleUpdate}
							errors={errors}
							note="Please upload a PDF scan of your IRS 501(c)3 determination letter."
						/>
					</div>

					<div>
						<Checkbox
							name="exempt"
							update={handleUpdate}
							note="If you are a school or college and don't have a 501(c)3 letter, please check the box above."
							data={[
								{
									value: false,
									label: "No IRS 503(c)3 letter",
								},
							].map((item) => {
								return {
									value: item.value,
									label: item.label,
									checked: names.exempt,
								};
							})}
							cares={cares}
						/>
					</div>

					<p className="line" />

					<div className="one">
						<InputFile
							label="W-9 Form"
							req={true}
							name="w9"
							value={names.w9}
							update={handleUpdate}
							errors={errors}
							note="Please upload a PDF of your completed W-9 form that is signed and dated in the current year."
						/>
					</div>

					<p className="line" />

					<div className="one">
						<InputFile
							label="Charity Logo"
							req={false}
							name="logo"
							value={names.logo}
							update={handleUpdate}
							errors={errors}
							note="If you wish to have your logo included on our BFC Donation page, please upload a JPG or PNG."
						/>
					</div>

					<p className="line" />

					<h3>Signature of Authorized Representative</h3>

					<div className="two">
						<div>
							<Input
								type="text"
								label="First Name"
								req={true}
								name="fname"
								value={names.fname}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
						<div>
							<Input
								type="text"
								label="Last Name"
								req={true}
								name="lname"
								value={names.lname}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
					</div>

					<div>
						<Checkbox
							name="acknowledge"
							update={handleUpdate}
							data={[
								{
									value: false,
									label:
										"I understand that my BFC check will be mailed to the charity address listed above.",
								},
							].map((item) => {
								return {
									value: item.value,
									label: item.label,
									checked: names.acknowledge,
								};
							})}
							cares={cares}
						/>
					</div>

					<div className="msg-submit">
						{msg.type && <Msg data={msg} />}

						<div
							className={msg.type === "working" ? "hidden" : "submit-container"}
						>
							<Submit
								name="Submit Signup Request"
								icon={faChevronCircleRight}
								cares={cares}
							/>
						</div>
					</div>

					<p>
						We will not share your email address with third-parties. For more
						information on how we use your information, see our{" "}
						<a href="https://img.com/privacypolicy/">Privacy Policy</a>.
					</p>
					<p className="spacer" />
				</form>
			)}
		</>
	);
};

export default Details1;
