import { useState } from "react";
import Details1 from "./Details1";
import Thanks from "./Thanks";
import Alert from "../../components/Alert";

const BFCSignup = ({ code, cares }) => {
  const [id, setId] = useState("");
  const [status, setStatus] = useState(2);

  /*
    0 - error submitting
    1 - details1 submitted
    2 - details1 not yet submitted
  */

  const handleStatus = (id = "", status) => {
    setId(id);
    setStatus(status);
  };

  return (
    <>
      {id && status === 1 ? (
        <Thanks id={id} />
      ) : (
        <Details1 code={code} status={handleStatus} cares={cares} />
      )}
    </>
  );
};

export default BFCSignup;
