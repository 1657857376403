import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

import { API_URL_AC } from "../../utils/helpers";

import Alert from "../../components/Alert";

const Receipt = ({ id }) => {
	const [order, setOrder] = useState({});
	const [alert, setAlert] = useState({
		type: "working",
		text: "",
	});

	useEffect(() => {
		window.scrollTo(0, 0);
		init(id);
	}, [id]);

	const init = async (id) => {
		const url = new URL(`${API_URL_AC}/`);
		const params = {
			f: "receiptInit",
			id,
			x: uuidv4(),
		};
		url.search = new URLSearchParams(params);

		try {
			const response = await fetch(url, {
				method: "GET",
				cache: "no-store",
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setOrder({
					id: json.id,
					date: json.date,
					phone: json.phone,
					items: json.items,
					totals: json.totals,
					text: json.text,
					emailed: json.emailed,
				});
				setAlert({});
			} else {
				setAlert({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setAlert({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	return (
		<>
			{alert.type ? (
				<Alert data={alert} />
			) : (
				<div className="receipt">
					<p>{order.text}</p>

					<p>{order.emailed}</p>

					<table className="submitted">
						<tbody>
							<tr>
								<th className="right">Order Number:</th>
								<td>{order.id}</td>
							</tr>
							<tr>
								<th className="right">Date Submitted:</th>
								<td>{order.date}</td>
							</tr>
						</tbody>
					</table>
					<p className="line" />
					<table className="order">
						<thead>
							<tr>
								<th>Qty</th>
								<th className="left">Item</th>
								<th>Amount</th>
							</tr>
						</thead>
						<tbody>
							{order.items.map((item, index) => {
								return (
									<tr key={index}>
										<td className="center">
											<span>{item.qty}</span>
										</td>
										<td>
											<div>
												<span>{item.item}</span>
												<small>{item.subitem}</small>
											</div>
										</td>
										<td className="right">{item.price}</td>
									</tr>
								);
							})}
						</tbody>
						<tfoot>
							<tr>
								<td colSpan="2" className="right">
									TOTAL
								</td>
								<td className="right">{order.totals.total}</td>
							</tr>
							<tr>
								<td colSpan="2" className="right">
									{order.totals.paid}
								</td>
								<td className="right">{order.totals.amt}</td>
							</tr>
						</tfoot>
					</table>

					<p>
						Thanks again for your support of The Classic in The Palm Beaches and
						participation in the 2023-24 Ambassadors’ Club.
					</p>

					<p>
						The 2024 Classic in The Palm Beaches will take place February 26 -
						March 3 at PGA National Resort & Spa. Please confirm that we have
						your name, email and cell phone ({order.phone}) correct as we need
						this info to correspond with you. Please email{" "}
						<a href="mailto:djohnson@thehondaclassic.com">
							djohnson@thehondaclassic.com
						</a>{" "}
						any questions or changes to your contact information.
					</p>

					<p className="spacer" />
				</div>
			)}
		</>
	);
};

export default Receipt;
