import { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";

import { API_URL_AC } from "../../utils/helpers";

import Alert from "../../components/Alert";
import Input from "../../components/Input";
import Checkbox from "../../components/Checkbox";
import Msg from "../../components/Msg";
import Submit from "../../components/Submit";

import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

const Details2 = ({ id, status }) => {
	const [names, setNames] = useState({});
	const [contact, setContact] = useState({});
	const [alert, setAlert] = useState({
		type: "working",
		text: "",
	});
	const [errors, setErrors] = useState([]);
	const [msg, setMsg] = useState({});

	useEffect(() => {
		window.scrollTo(0, 0);
		init(id);
	}, [id]);

	const init = async (id) => {
		const url = new URL(`${API_URL_AC}/`);
		const params = {
			f: "details2Init",
			id,
			x: uuidv4(),
		};
		url.search = new URLSearchParams(params);

		try {
			const response = await fetch(url, {
				method: "GET",
				cache: "no-store",
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setContact({
					name: json.name,
					line1: json.line1,
					line2: json.line2,
					phone: json.phone,
					email: json.email,
				});

				setAlert({});
			} else {
				setAlert({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setAlert({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	const handleUpdate = (name, value) => {
		if (name === "fees") {
			const checked = !names.fees;
			setNames((names) => ({ ...names, [name]: checked }));
		} else if (name === "donation") {
			if (!isNaN(value.replace("$", ""))) {
				const donationAmt = value.replace("$", "");
				setNames((names) => ({
					...names,
					[name]: value ? Number(donationAmt) : 0,
				}));
			}
		} else {
			setNames((names) => ({ ...names, [name]: value ? value : "" }));
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setErrors([]);
		setMsg({
			type: "working",
			text: "",
		});

		const url = new URL(`${API_URL_AC}/?f=details2`);
		let formData = new FormData();
		Object.entries(names).forEach(([key, value]) => {
			if (key === "fees") {
				formData.append(key, value ? "1" : "0");
			} else {
				formData.append(key, value);
			}
		});
		formData.append("id", id || "0");

		try {
			const response = await fetch(url, {
				method: "POST",
				body: formData,
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setAlert({});
				status(json.id, 2);
			} else {
				setErrors(json.fields);
				setMsg({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setMsg({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	return (
		<>
			{alert.type ? (
				<Alert data={alert} />
			) : (
				<>
					<form method="post" action="/" onSubmit={(e) => handleSubmit(e)}>
						<p>
							Thank you for filling out the club registration form, if you would
							like to optionally make a donation to The Classic Cares or elect
							to cover our processing fees, please indicate as appropriate
							below.
						</p>

						<div className="grid">
							<p>
								{contact.name}
								<br />
								{contact.line1}
								<br />
								{contact.line2}
							</p>
							<p>
								{contact.phone}
								<br />
								{contact.email}
							</p>
						</div>

						<p className="line" />

						<p>
							Enter an amount (i.e. $100) below to make 100% tax deductible
							donation to The Classic Cares.
						</p>

						<div className="one">
							<div>
								<Input
									type="text"
									label="Donation to Honda Classic Cares"
									note=""
									req={false}
									name="donation"
									value={names.donation}
									update={handleUpdate}
									errors={errors}
								/>
							</div>
						</div>

						<p className="line" />

						<div>
							<Checkbox
								name="fees"
								update={handleUpdate}
								data={[
									{
										value: false,
										label:
											"Yes, I would like to cover the 2.7% in processing fees",
									},
								].map((item) => {
									return {
										value: item.value,
										label: item.label,
										checked: names.fees,
									};
								})}
							/>
						</div>

						<div className="msg-submit">
							{msg.type && <Msg data={msg} />}

							<div
								className={
									msg.type === "working" ? "hidden" : "submit-container"
								}
							>
								<Submit
									name="Continue to Payment"
									icon={faChevronCircleRight}
								/>
							</div>
						</div>
					</form>
				</>
			)}
		</>
	);
};

export default Details2;
