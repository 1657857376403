import { useState } from "react";

import { API_URL_CONTACT as API_URL } from "../../utils/helpers";

import Select from "../../components/Select";
import Input from "../../components/Input";
import Textarea from "../../components/Textarea";
import Checkbox from "../../components/Checkbox";
import Msg from "../../components/Msg";
import Submit from "../../components/Submit";
import Alert from "../../components/Alert";

import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

const Contact = ({ cares }) => {
	const [errors, setErrors] = useState([]);
	const [names, setNames] = useState({
		subject: cares ? "Children’s Healthcare Charity" : "",
	});
	const [msg, setMsg] = useState({});
	const [alert, setAlert] = useState({});

	const subjects = cares
		? [
				{
					value: "Children’s Healthcare Charity",
					name: "Children’s Healthcare Charity",
				},
		  ]
		: [
				{ value: "", name: "--" },
				{
					value: "General Question / Comment",
					name: "General Question / Comment",
				},
				{ value: "Ticket Information", name: "Ticket Information" },
				{
					value: "Parking / Transportation",
					name: "Parking / Transportation",
				},
				{ value: "Volunteering", name: "Volunteering" },
				{ value: "Sponsorships", name: "Sponsorships" },
				{ value: "Patriots Outpost", name: "Patriots Outpost" },
				{ value: "The Classic Cares", name: "The Classic Cares" },
				{ value: "Other", name: "Other" },
		  ];

	const handleUpdate = (name, value) => {
		if (name === "optin") {
			setNames((names) => ({ ...names, optin: !names.optin }));
		} else {
			setNames((names) => ({ ...names, [name]: value ? value : "" }));
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setErrors([]);
		setMsg({
			type: "working",
			text: "",
		});

		const url = new URL(`${API_URL}/?f=contact`);

		let data = new FormData();
		data.append("form", cares ? "CHC" : "The Classic");
		data.append("subject", names.subject || "");
		data.append("fname", names.fname || "");
		data.append("lname", names.lname || "");
		data.append("email", names.email || "");
		data.append("phone", names.phone || "");
		data.append("notes", names.notes || "");
		data.append("optin", names.optin ? "1" : "0");

		try {
			const response = await fetch(url, {
				method: "POST",
				body: data,
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setMsg({});
				setAlert({
					type: "success",
					text: json.text,
				});
			} else {
				setErrors(json.fields);
				setMsg({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setMsg({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	return (
		<>
			{alert.type ? (
				<Alert data={alert} />
			) : (
				<form method="post" action="/" onSubmit={(e) => handleSubmit(e)}>
					<p>
						Please use the contact form below to contact us with your question
						or comment about Children’s Healthcare Charity. We make every effort
						to respond you your inquiry in a timely manner. Thank you for your
						interest in Children’s Healthcare Charity!
					</p>

					<div className="one">
						<Select
							label="Subject of Email"
							req="true"
							name="subject"
							value=""
							data={subjects}
							update={handleUpdate}
							errors={errors}
						/>
					</div>

					<div className="two">
						<div>
							<Input
								type="text"
								label="First Name"
								req={true}
								name="fname"
								value={names.fname}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
						<div>
							<Input
								type="text"
								label="Last Name"
								req={true}
								name="lname"
								value={names.lname}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
					</div>

					<div className="two">
						<div>
							<Input
								type="text"
								label="Email Address"
								req={true}
								name="email"
								value={names.email}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
						<div>
							<Input
								type="text"
								label="Phone"
								req={false}
								name="phone"
								value={names.phone}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
					</div>

					<div className="one">
						<Textarea
							label="Question or Comment"
							req={true}
							name="notes"
							value={names.notes}
							update={handleUpdate}
							errors={errors}
						/>
					</div>

					<div className="one">
						<Checkbox
							name="optin"
							update={handleUpdate}
							data={[
								{
									value: false,
									label: "Check to sign up for news & updates",
								},
							].map((item) => {
								return {
									value: item.value,
									label: item.label,
									checked: names.optin,
								};
							})}
						/>
					</div>

					<div className="msg-submit">
						{msg.type && <Msg data={msg} />}

						<div
							className={msg.type === "working" ? "hidden" : "submit-container"}
						>
							<Submit
								name="Submit Inquiry"
								icon={faChevronCircleRight}
								cares={cares}
							/>
						</div>
					</div>

					<p>
						We will not share your name or email address with third-parties.
					</p>
					<p className="spacer" />
				</form>
			)}
		</>
	);
};

export default Contact;
