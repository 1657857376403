import { useState, useEffect } from "react";
import { API_URL_WLF } from "../../utils/helpers";
import Alert from "../../components/Alert";

const Receipt = ({ id }) => {
  const [order, setOrder] = useState({});
  const [alert, setAlert] = useState({
    type: "working",
    text: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    init(id);
  }, [id]);

  const init = async (id) => {
    const url = new URL(`${API_URL_WLF}/receiptInit`);
    const params = { id };
    url.search = new URLSearchParams(params);

    try {
      const response = await fetch(url, {
        method: "GET",
        cache: "no-store",
      });
      const json = await response.json();
      if (json && json.resp === 1) {
        setOrder({
          id: json.id,
          date: json.date,
          phone: json.phone,
          items: json.items,
          totals: json.totals,
          text: json.text,
          emailed: json.emailed,
        });
        setAlert({});
      } else {
        setAlert({
          type: "error",
          text: json.text,
        });
      }
    } catch (error) {
      setAlert({
        type: "error",
        text: "An error has occurred.",
      });
    }
  };

  return (
    <>
      {alert.type ? (
        <Alert data={alert} />
      ) : (
        <div className="receipt">
          <p>{order.text}</p>

          <p>{order.emailed}</p>

          <table className="submitted">
            <tbody>
              <tr>
                <th className="right">Order Number:</th>
                <td>{order.id}</td>
              </tr>
              <tr>
                <th className="right">Date Submitted:</th>
                <td>{order.date}</td>
              </tr>
            </tbody>
          </table>

          <p className="line" />

          <table className="order">
            <thead>
              <tr>
                <th>Qty</th>
                <th className="left">Item</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {order.items.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="center">
                      <span>{item.qty}</span>
                    </td>
                    <td>
                      <div>
                        <span>{item.item}</span>
                        <small>{item.subitem}</small>
                      </div>
                    </td>
                    <td className="right">{item.price}</td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="2" className="right">
                  TOTAL
                </td>
                <td className="right">{order.totals.total}</td>
              </tr>
              <tr>
                <td colSpan="2" className="right">
                  {order.totals.paid}
                </td>
                <td className="right">{order.totals.amt}</td>
              </tr>
            </tfoot>
          </table>

          <p>
            Thanks again for your support of The Classic in the Palm Beaches and participation
            in the 2023 Women’s Leadership Forum.
          </p>

          <p>
            The 2023 Honda Classic will take place February 20 - 26 at PGA
            National Resort & Spa. Please confirm that we have your name, email
            and cell phone ({order.phone}) correct as we may need this info to
            correspond with you. Please email{" "}
            <a href="mailto:djohnson@thehondaclassic.com">
              djohnson@thehondaclassic.com
            </a>{" "}
            any questions or changes to your contact information.
          </p>

          <p className="spacer" />
        </div>
      )}
    </>
  );
};

export default Receipt;
