import { useState } from "react";

import Alert from "../../components/Alert";

import Details1 from "./Details1";
import Details2 from "./Details2";
import Payment from "./Payment";
import Receipt from "./Receipt";

const AmbassadorsClub = ({ code }) => {
	const [id, setId] = useState("");
	const [status, setStatus] = useState(4);

	/*
    0 - error submitting payment
    1 - payment submitted successfully
    2 - details2 submitted
    3 - details submitted
    4 - details not yet submitted
  */

	const handleStatus = (id = "", status) => {
		setId(id);
		setStatus(status);
	};

	return (
		<>
			{id && status === 1 ? (
				<Receipt id={id} />
			) : id && status === 2 ? (
				<Payment id={id} status={handleStatus} />
			) : id && status === 3 ? (
				<Details2 id={id} status={handleStatus} />
			) : code ? (
				<Details1 code={code} status={handleStatus} />
			) : (
				<Alert data={{ type: "error", text: "An error has occurred." }} />
			)}
		</>
	);
};

export default AmbassadorsClub;
