import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { API_URL_VKS as API_URL, getObjFromLS } from "../../utils/helpers";

import Alert from "../../components/Alert";
import InputFile from "../../components/InputFile";
import Textarea from "../../components/Textarea";
import Msg from "../../components/Msg";
import Submit from "../../components/Submit";

import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

function Quest8({ cares }) {
  const [user, setUser] = useState({});
  const [errors, setErrors] = useState([]);
  const [names, setNames] = useState({});
  const [msg, setMsg] = useState({});
  const [alert, setAlert] = useState({
    type: "working",
    text: "",
  });

  const formElement = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    if (getObjFromLS("scholarship")) {
      const obj = getObjFromLS("scholarship");
      setUser({
        token: obj.token,
        id: obj.id,
        email: obj.email,
      });

      init(obj.token);
    } else {
      navigate("/enter");
    }
  }, []);

  const init = async (token) => {
    const url = new URL(`${API_URL}/admin/stepInit`);
    const params = { token, step: "quest8" };
    url.search = new URLSearchParams(params);

    try {
      const response = await fetch(url, {
        method: "GET",
        cache: "no-store",
      });
      const json = await response.json();
      if (json && json.resp === 1) {
        setNames((names) => ({ ...names, ...json }));

        setAlert({});
      } else {
        setAlert({
          type: "error",
          text: "An error occurred accessing your application.",
        });
      }
    } catch (error) {
      setAlert({
        type: "error",
        text: "An error occurred accessing your application.",
      });
    }
  };

  const handleUpdate = (name, value) => {
    setNames((names) => ({ ...names, [name]: value ? value : "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors([]);
    setMsg({
      type: "working",
      text: "",
    });

    const url = new URL(`${API_URL}/admin/quest8`);
    let formData = new FormData();
    Object.entries(names).forEach(([key, value]) =>
      formData.append(key, value)
    );
    formData.append("step", "quest7");
    formData.append("token", user.token);

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });
      const json = await response.json();
      if (json && json.resp === 1) {
        navigate("/");
      } else {
        setErrors(json.fields);
        setMsg({
          type: "error",
          text: json.text,
        });
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      });
    }
  };

  return (
    <>
      {alert.type ? (
        <Alert data={alert} />
      ) : (
        <form
          method="post"
          action="/"
          onSubmit={(e) => handleSubmit(e)}
          ref={formElement}
        >
          <h2>Section #8 - Additional Information</h2>

          <Link to="/">Back to Home</Link>

          <p>
            Mrs. Kingdom has made a significant contribution to golf in our
            area. A native of Virginia, Kingdom was presented with a Founder’s
            Award by the African American Golfers Hall of Fame in 2019 for her
            dedication to teaching golf to local youngsters. She was the first
            Black woman to work as a female locker room attendant at PGA
            National Resort and Spa from 1985 to 1997.
          </p>

          <p>
            <a
              href="https://www.palmbeachpost.com/story/news/local/westpb/2021/02/13/victoria-kingdom-hall-fame-golf-civil-rights-riviera/6739609002/"
              target="_blank"
              rel="noopener"
            >
              Read more from a February 13, 2021 article from The Palm Beach
              Post
            </a>
          </p>

          <p className="line" />

          <p>
            In 300 words or less, please share with us what this scholarship
            would mean to you in your college career and your interest in the
            game of golf. Please upload your document in PDF format.
          </p>

          <div className="one">
            <InputFile
              label="Upload PDF"
              req={true}
              name="pdf"
              value={names.pdf}
              update={handleUpdate}
              errors={errors}
            />
          </div>

          {names.essay && (
            <p>
              <a href={names.essay} target="_blank" rel="noopener">
                View Uploaded Document
              </a>
            </p>
          )}

          <p className="line" />

          <div>
            <div>
              <Textarea
                label="If there is additional information about you and your candidacy that the committee should know, please provide it here."
                req={false}
                name="additional"
                value={names.additional}
                update={handleUpdate}
                errors={errors}
              />
            </div>
          </div>

          <div className="msg-submit">
            {msg.type && <Msg data={msg} />}

            <div
              className={msg.type === "working" ? "hidden" : "submit-container"}
            >
              <Submit
                name="Submit Section #8"
                icon={faChevronCircleRight}
                cares={cares}
              />
            </div>
          </div>
          <p>
            We will not share your email address with third-parties. For more
            information on how we use your information, see our{" "}
            <a
              href="https://img.com/privacypolicy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            .
          </p>
          <p className="spacer" />
        </form>
      )}
    </>
  );
}

export default Quest8;
