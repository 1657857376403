import React from "react";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCheckCircle } from "@fortawesome/pro-light-svg-icons";

const Radios = ({ req = true, name, hdg, update, data, cares = false }) => {
  return (
    <div className={cares ? "radios cares" : "radios"}>
      <label>
        {`${hdg}`} {req ? <span className="error">*</span> : null}
      </label>
      <div>
        {data.map((item, index) => {
          return (
            <label key={index} onClick={(e) => update(name, item.value)}>
              <FontAwesomeIcon icon={item.checked ? faCheckCircle : faCircle} />
              <span>{item.label}</span>
            </label>
          );
        })}
      </div>
    </div>
  );
};

export default Radios;
