import { useState } from "react";
import Details1 from "./Details1";
import Details2 from "./Details2";
import Payment from "./Payment";
import Receipt from "./Receipt";

const WLFTickets = () => {
  const [id, setId] = useState("");
  const [status, setStatus] = useState(4);

  /*
    0 - error submitting payment
    1 - payment submitted successfully
    2 - details2 submitted
    3 - details1 submitted
    4 - details1 not yet submitted
  */

  const handleStatus = (id = "", status) => {
    setId(id);
    setStatus(status);
  };

  return (
    <>
      {id && status === 1 ? (
        <Receipt id={id} />
      ) : id && status === 2 ? (
        <Payment id={id} status={handleStatus} />
      ) : id && status === 3 ? (
        <Details2 id={id} status={handleStatus} />
      ) : (
        <Details1 status={handleStatus} />
      )}
    </>
  );
};

export default WLFTickets;
