import { useState, useEffect, useRef } from "react";
import { API_URL_DONATE } from "../../utils/helpers";

import Input from "../../components/Input";
import Msg from "../../components/Msg";
import Submit from "../../components/Submit";
import Alert from "../../components/Alert";

import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

const Details = ({ cares }) => {
	const [errors, setErrors] = useState([]);
	const [names, setNames] = useState({});
	const [msg, setMsg] = useState({});
	const [alert] = useState({});

	const formElement = useRef();

	useEffect(() => {
		handleUpdate("reference", "Tito’s Cares");
		formElement.current.reference.value = "Tito’s Cares";
	}, []);

	const handleUpdate = (name, value) => {
		setNames((names) => ({ ...names, [name]: value ? value : "" }));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setErrors([]);
		setMsg({
			type: "working",
			text: "",
		});

		const url = new URL(`${API_URL_DONATE}/?f=entry`);
		let data = new FormData();
		Object.entries(names).forEach(([key, value]) => data.append(key, value));

		try {
			const response = await fetch(url, {
				method: "POST",
				body: data,
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				formElement.current.reset();
				setNames({});
				setMsg({
					type: "success",
					text: json.text,
				});
			} else {
				setErrors(json.fields);
				setMsg({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setMsg({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	return (
		<>
			{alert.type ? (
				<Alert data={alert} />
			) : (
				<form
					method="post"
					action="/"
					onSubmit={(e) => handleSubmit(e)}
					ref={formElement}
				>
					<h3>Tito’s Giveaway without a Donation</h3>

					<div className="two">
						<div>
							<Input
								type="text"
								label="First Name"
								req={true}
								name="fname"
								value={names.fname}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
						<div>
							<Input
								type="text"
								label="Last Name"
								req={true}
								name="lname"
								value={names.lname}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
					</div>
					<div className="two">
						<div>
							<Input
								type="text"
								label="Email Address"
								req={true}
								name="email"
								value={names.email}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
						<div>
							<Input
								type="text"
								label="Phone"
								req={false}
								name="phone"
								value={names.phone}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
					</div>
					<div className="one">
						<Input
							type="text"
							label="Reference"
							req={false}
							name="reference"
							value={names.notes}
							update={handleUpdate}
							errors={errors}
						/>
					</div>

					<p className="line" />

					<div className="msg-submit">
						{msg.type && <Msg data={msg} />}

						<div
							className={
								msg.type === "working" || msg.type === "success"
									? "hidden"
									: "submit-container"
							}
						>
							<Submit
								name="Submit Entry"
								icon={faChevronCircleRight}
								cares={cares}
							/>
						</div>
					</div>
					<p>
						We will not share your name or email address with third-parties.
					</p>

					<p className="spacer" />
				</form>
			)}
		</>
	);
};

export default Details;
