import { useState } from "react";
import {
  API_URL_WLF,
  STATES,
  WLFTICKETS,
  fmtCurrency,
} from "../../utils/helpers";
import Select from "../../components/Select";
import Input from "../../components/Input";
import Radios from "../../components/Radios";
import Msg from "../../components/Msg";
import Submit from "../../components/Submit";
import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

const Details1 = ({ code, status }) => {
  const [errors, setErrors] = useState([]);
  const [names, setNames] = useState({});
  const [msg, setMsg] = useState({});

  const handleUpdate = (name, value) => {
    setNames((names) => ({ ...names, [name]: value ? value : "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors([]);
    setMsg({
      type: "working",
      text: "",
    });

    const url = new URL(`${API_URL_WLF}/details1`);
    let data = new FormData();
    Object.entries(names).forEach(([key, value]) => data.append(key, value));
    data.append("ticketDetails", JSON.stringify(WLFTICKETS[names.ticket]));

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
      });
      const json = await response.json();
      if (json && json.resp === 1) {
        setMsg({});
        status(json.id, 3);
      } else {
        setErrors(json.fields);
        setMsg({
          type: "error",
          text: json.text,
        });
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      });
    }
  };

  return (
    <form method="post" action="/" onSubmit={(e) => handleSubmit(e)}>
      <p style={{ marginBottom: "0" }}>
        The eleventh annual Women’s Leadership Forum presented by Jupiter
        Medical Center promises to be an amazing day! More than 300 women will
        gather at the beautiful PGA National Resort & Spa to participate in an
        engaging morning, taking on topics vital to their professional and
        personal development.
      </p>

      <div className="grid" style={{ marginTop: "0" }}>
        <p>
          Tuesday, February 21, 2023
          <br />
          PGA National Resort & Spa
          <br />
          8:00 - 11:15 am
        </p>
        <p>Speaker: Shannon Miller</p>
      </div>

      <p className="line" style={{ marginTop: "5px" }} />

      <div className="two">
        <div>
          <Input
            type="text"
            label="First Name"
            req={true}
            name="fname"
            value={names.fname}
            update={handleUpdate}
            errors={errors}
          />
        </div>
        <div>
          <Input
            type="text"
            label="Last Name"
            req={true}
            name="lname"
            value={names.lname}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <div className="two">
        <div>
          <Input
            type="text"
            label="Company / Organization"
            req={false}
            name="company"
            value={names.company}
            update={handleUpdate}
            errors={errors}
          />
        </div>
        <div className="empty" />
      </div>

      <div className="two">
        <div>
          <Input
            type="text"
            label="Email Address"
            req={true}
            name="email"
            value={names.email}
            update={handleUpdate}
            errors={errors}
          />
        </div>

        <div>
          <Input
            type="text"
            label="Phone"
            req={true}
            name="phone"
            value={names.phone}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <p className="line" />

      <div className="two">
        <div>
          <Input
            type="text"
            label="Address"
            req={true}
            name="address"
            value={names.address}
            update={handleUpdate}
            errors={errors}
          />
        </div>
        <div>
          <Input
            type="text"
            label="Address 2"
            req={false}
            name="address2"
            value={names.address2}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>
      <div className="three">
        <div>
          <Input
            type="text"
            label="City"
            req={true}
            name="city"
            value={names.city}
            update={handleUpdate}
            errors={errors}
          />
        </div>
        <div className="small">
          <Select
            label="State"
            req={true}
            name="st"
            value={names.st}
            data={STATES}
            update={handleUpdate}
            errors={errors}
          />
        </div>
        <div className="small">
          <Input
            type="text"
            label="Zip"
            req={true}
            name="zip"
            value={names.zip}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <p className="line" style={{ paddingBottom: "15px" }} />

      <div>
        <Radios
          name="ticket"
          hdg="Ticket Type"
          update={handleUpdate}
          data={[
            {
              value: "individual",
              label: `${WLFTICKETS.individual.name} (1 seat) ${fmtCurrency(
                WLFTICKETS.individual.amt / 100
              )}`,
            },
            {
              value: "table",
              label: `${WLFTICKETS.table.name} (10 seats) ${fmtCurrency(
                WLFTICKETS.table.amt / 100
              )}`,
            },
          ].map((item) => {
            return {
              value: item.value,
              label: item.label,
              checked: names.ticket === item.value ? true : false,
            };
          })}
        />
      </div>

      <div className="two">
        <Select
          label="Quantity"
          req={true}
          name="qty"
          value={names.qty}
          data={[
            { value: 0, name: "--" },
            { value: 1, name: "1" },
            { value: 2, name: "2" },
            { value: 3, name: "3" },
            { value: 4, name: "4" },
            { value: 5, name: "5" },
            { value: 6, name: "6" },
            { value: 7, name: "7" },
            { value: 8, name: "8" },
            { value: 9, name: "9" },
            { value: 10, name: "10" },
          ]}
          update={handleUpdate}
          errors={errors}
        />

        <div className="empty" />
      </div>

      {names.ticket && names.qty > 0 && (
        <div className="receipt" style={{ marginTop: "25px" }}>
          <table className="order">
            <thead>
              <tr>
                <th>Qty</th>
                <th className="left">Item</th>
                <th>Amount</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="center">
                  <span>{names.qty}</span>
                </td>
                <td>
                  <div>
                    <span>{WLFTICKETS[names.ticket].name}</span>
                    <small>2023 Women’s Leadership Forum</small>
                  </div>
                </td>
                <td className="right">
                  {fmtCurrency(WLFTICKETS[names.ticket].amt / 100)}
                </td>
                <td className="right">
                  {fmtCurrency(
                    (names.qty * WLFTICKETS[names.ticket].amt) / 100
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      <div className="msg-submit">
        {msg.type && <Msg data={msg} />}

        <div className={msg.type === "working" ? "hidden" : "submit-container"}>
          <Submit name="Continue" icon={faChevronCircleRight} />
        </div>
      </div>
      <p>
        We will not share your email address with third-parties. For more
        information on how we use your information, see our{" "}
        <a
          href="https://img.com/privacypolicy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        .
      </p>
      <p className="spacer" />
    </form>
  );
};

export default Details1;
