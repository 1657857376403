function Waiver() {
  return (
    <div className="waiver">
      <p>
        In connection with my activities and/or the activities of my minor
        child, I acknowledge that the provision of my time and services, and/or
        the time or services of my minor child, are as a volunteer at the 2023
        Honda Classic Golf Tournament (the “Tournament”) being held at PGA
        National Resort and Spa in Palm Beach Gardens, Florida (the “Club”)
        without the expectation of payment and I understand that neither I nor
        my minor child will be compensated in connection with the Tournament. I
        acknowledge that neither I nor my minor child is or will be an employee
        of the Club, The Classic in the Palm Beaches, Children’s Healthcare Charity, Inc.
        (“CHC”), Nicklaus Children’s Health Care Foundation, the PGA TOUR, IMG
        Worldwide, LLC (“IMG”), WME, Endeavor, any sponsor of the Tournament, or
        any of their respective officers, partners, directors, members,
        employees, representatives, parent companies, affiliates, subsidiaries,
        insurers, successors and assigns, as well as any other volunteer (the
        “Tournament Parties”). I agree to comply, and cause my minor child to
        comply (to the extent applicable), with all Tournament and Club rules.
        In connection with my and/or my minor child’s activities as a volunteer
        at the Club for the Tournament, I agree and understand that the presence
        of me and/or my minor child at the Tournament and any volunteer work or
        services performed by me and/or my minor child for the Tournament
        Parties may expose me and/or my minor child to both known and
        unanticipated risks of harm or injury. In consideration of and as a
        prerequisite to the participation of me and/or my minor child as a
        volunteer, I, for myself and/or my minor child (to the extent
        applicable), acknowledge that such risks exist, hereby assume all such
        risks, and release and discharge the Tournament Parties and each of them
        from any and all claims for liability for personal injury (including
        death) and property damage that I and/or my minor child may suffer while
        performing such volunteer work or service, whether or not on the
        premises of the Club, including, without limitation, any claim arising
        out of any condition of the premises owned by any of the Tournament
        Parties, or the conduct of any person in connection with, supervision of
        or conduct of the Tournament or any practice round or activity connected
        or related to the Tournament. I further understand that the Tournament
        Parties may not maintain insurance which will cover me and/or my minor
        child for personal injury, property damage or medical expenses, and I
        accept full responsibility for the costs of treatment for any injury or
        damages suffered by me and/or my minor child while participating as a
        volunteer in connection with the Tournament. I further agree that in
        case any action being brought for or on behalf of myself and/or my minor
        child on account of any injury or damage sustained by me and/or my minor
        child while volunteering in connection with the Tournament, I will be
        personally responsible to, and agree to repay to, any of the Tournament
        Parties and hold each of them harmless against any amounts recovered in
        connection with any such action and all related costs incurred.
      </p>
    </div>
  );
}

export default Waiver;
