import { useState, useEffect, useRef } from "react";

import { API_URL_BFC as API_URL } from "../../utils/helpers";
import { v4 as uuidv4 } from "uuid";

import Charity from "../BFCDonate/Charity";

import Input from "../../components/Input";
import Msg from "../../components/Msg";
import Submit from "../../components/Submit";
import Alert from "../../components/Alert";

import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

const BFCReport = ({ code, cares }) => {
	const [details, setDetails] = useState({});
	const [errors, setErrors] = useState([]);
	const [names, setNames] = useState({});
	const [msg, setMsg] = useState({});
	const [alert, setAlert] = useState({
		type: "working",
		text: "",
	});

	const initRef = useRef(false);

	useEffect(() => {
		if (initRef.current) return;
		initRef.current = true;
		init();
	});

	const init = async () => {
		const url = new URL(`${API_URL}/`);
		const params = {
			f: "reportInit",
			code,
			x: uuidv4(),
		};
		url.search = new URLSearchParams(params);

		try {
			const response = await fetch(url, {
				method: "GET",
				cache: "no-store",
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setDetails(json.charity);
				setNames({
					email: json.email,
				});
				setAlert({});
			} else {
				setAlert({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setAlert({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	const handleUpdate = (name, value) => {
		setNames((names) => ({ ...names, [name]: value ? value : "" }));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setErrors([]);
		setMsg({
			type: "working",
			text: "",
		});

		const url = new URL(`${API_URL}/?f=report`);
		let formData = new FormData();
		Object.entries(names).forEach(([key, value]) => {
			formData.append(key, value);
		});
		formData.append("code", code || "");

		try {
			const response = await fetch(url, {
				method: "POST",
				body: formData,
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setMsg({});
				setAlert({
					type: "success",
					text: json.text,
				});
			} else {
				setErrors(json.fields);
				setMsg({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setMsg({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	return (
		<>
			{alert.type ? (
				<Alert data={alert} />
			) : (
				<form method="post" action="/" onSubmit={(e) => handleSubmit(e)}>
					<p className="line less-top-margin" />

					<Charity details={details} />

					<p className="line" />

					<p>
						If you would like to receive a report of your donations collected
						during the 2024 BFC campaign, please verify your email address and
						click below to generate an email with a .csv file attached.
					</p>

					<div className="one">
						<div>
							<Input
								type="text"
								label="Email Address"
								note=""
								req={true}
								name="email"
								value={names.email}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
					</div>

					<div className="msg-submit">
						{msg.type && <Msg data={msg} />}

						<div
							className={msg.type === "working" ? "hidden" : "submit-container"}
						>
							<Submit
								name="Generate & Email Report"
								icon={faChevronCircleRight}
								cares={cares}
							/>
						</div>
					</div>

					<p className="spacer" />
				</form>
			)}
		</>
	);
};

export default BFCReport;
