import React from "react";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Submit = ({ name, icon, cares = false }) => {
  return (
    <div className={cares ? "submit cares" : "submit"}>
      <button type="submit">
        <span>
          {icon && <FontAwesomeIcon icon={icon} size="lg" />}
          <span>{name}</span>
        </span>
      </button>
    </div>
  );
};

export default Submit;
