import React from "react";
import "./styles.css";

const ButtonLink = ({ data, blank = true }) => {
  return (
    <a
      href={data.url}
      className="btn-link"
      target={blank ? "_blank" : ""}
      rel="noopener noreferrer"
    >
      <span>{data.name}</span>
    </a>
  );
};

export default ButtonLink;
