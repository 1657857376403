import { useState, useRef } from "react";
import { API_URL_KICKOFF, STATES, fmtCurrency } from "../../utils/helpers";

import Notice from "../../components/Notice";
import Select from "../../components/Select";
import Input from "../../components/Input";
import InputUncontrolled from "../../components/InputUncontrolled";
import Msg from "../../components/Msg";
import Submit from "../../components/Submit";

import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

function createArray(int) {
  let array = [];
  for (let n = 1; n <= int; n++) {
    array.push(n);
  }
  return array;
}

const Details1 = ({ code, status }) => {
  const [errors, setErrors] = useState([]);
  const [names, setNames] = useState({
    qty: 0,
  });
  const [msg, setMsg] = useState({});

  const formElement = useRef();

  const handleUpdate = (name, value) => {
    setNames((names) => ({ ...names, [name]: value ? value : "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors([]);
    setMsg({
      type: "working",
      text: "",
    });

    const url = new URL(`${API_URL_KICKOFF}/details1`);
    let data = new FormData(formElement.current);
    //Object.entries(names).forEach(([key, value]) => data.append(key, value));

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
      });
      const json = await response.json();
      if (json && json.resp === 1) {
        setMsg({});
        status(json.id, 2);
      } else {
        setErrors(json.fields);
        setMsg({
          type: "error",
          text: json.text,
        });
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      });
    }
  };

  return (
    <form
      method="post"
      action="/"
      onSubmit={(e) => handleSubmit(e)}
      ref={formElement}
    >
      <p style={{ marginBottom: "0" }}>
        Join us for the official opening celebration with cocktails, hors
        d’oeuvres, live music, silent auction and more. $50 admission charge per
        person with all proceeds benefiting The Classic in the Palm Beaches Cares charitable
        initiatives. Tickets may be purchase at the door.
      </p>

      <div className="grid" style={{ marginTop: "0" }}>
        <p>
          Thursday, February 16, 2023
          <br />
          PGA National Resort – 9th Green Terrace
          <br />
          6:00 – 9:00 pm
        </p>
        <p>Tickets $50 per Person</p>
      </div>

      <p className="line" style={{ marginTop: "5px" }} />

      <div className="two">
        <Select
          label="Number of Tickets"
          req={true}
          name="qty"
          value={names.qty}
          data={[
            { value: 0, name: "--" },
            { value: 1, name: "1" },
            { value: 2, name: "2" },
            { value: 3, name: "3" },
            { value: 4, name: "4" },
            { value: 5, name: "5" },
            { value: 6, name: "6" },
            { value: 7, name: "7" },
            { value: 8, name: "8" },
            { value: 9, name: "9" },
            { value: 10, name: "10" },
          ]}
          update={handleUpdate}
          errors={errors}
        />

        <div className="empty" />
      </div>

      <p className="line" />

      <h3>Attendee Names</h3>

      {names.qty > 0 ? (
        createArray(names.qty).map((item) => {
          return (
            <div className="two" key={item}>
              <div>
                <InputUncontrolled
                  type="text"
                  label={`First Name #${item}`}
                  req={true}
                  id={`guestFname${item}`}
                  name="guestFname[]"
                  errors={errors}
                />
              </div>
              <div>
                <InputUncontrolled
                  type="text"
                  label={`Last Name #${item}`}
                  req={true}
                  id={`guestLname${item}`}
                  name="guestLname[]"
                  errors={errors}
                />
              </div>
            </div>
          );
        })
      ) : (
        <Notice
          data={{
            type: "notice",
            text: "Please select above the number of tickets to purchase.",
          }}
        />
      )}

      <p className="line" />

      <h3>Billing Information</h3>

      <div className="two">
        <div>
          <Input
            type="text"
            label="First Name"
            req={true}
            name="fname"
            value={names.fname}
            update={handleUpdate}
            errors={errors}
          />
        </div>
        <div>
          <Input
            type="text"
            label="Last Name"
            req={true}
            name="lname"
            value={names.lname}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <div className="two">
        <div>
          <Input
            type="text"
            label="Company / Organization"
            req={false}
            name="company"
            value={names.company}
            update={handleUpdate}
            errors={errors}
          />
        </div>
        <div className="empty" />
      </div>

      <div className="two">
        <div>
          <Input
            type="text"
            label="Email Address"
            req={true}
            name="email"
            value={names.email}
            update={handleUpdate}
            errors={errors}
          />
        </div>

        <div>
          <Input
            type="text"
            label="Phone"
            req={true}
            name="phone"
            value={names.phone}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <p className="line" />

      <div className="two">
        <div>
          <Input
            type="text"
            label="Address"
            req={true}
            name="address"
            value={names.address}
            update={handleUpdate}
            errors={errors}
          />
        </div>
        <div>
          <Input
            type="text"
            label="Address 2"
            req={false}
            name="address2"
            value={names.address2}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>
      <div className="three">
        <div>
          <Input
            type="text"
            label="City"
            req={true}
            name="city"
            value={names.city}
            update={handleUpdate}
            errors={errors}
          />
        </div>
        <div className="small">
          <Select
            label="State"
            req={true}
            name="st"
            value={names.st}
            data={STATES}
            update={handleUpdate}
            errors={errors}
          />
        </div>
        <div className="small">
          <Input
            type="text"
            label="Zip"
            req={true}
            name="zip"
            value={names.zip}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      {names.qty > 0 && (
        <div className="receipt" style={{ marginTop: "40px" }}>
          <table className="order">
            <thead>
              <tr>
                <th>Qty</th>
                <th className="left">Item</th>
                <th>Amount</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="center">
                  <span>{names.qty}</span>
                </td>
                <td>
                  <div>
                    <span>Guest Ticket</span>
                    <small>2023 Honda Classic Kickoff Party</small>
                  </div>
                </td>
                <td className="right">{fmtCurrency(5000 / 100)}</td>
                <td className="right">
                  {fmtCurrency((names.qty * 5000) / 100)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      <div className="msg-submit">
        {msg.type && <Msg data={msg} />}

        <div className={msg.type === "working" ? "hidden" : "submit-container"}>
          <Submit name="Continue to Payment" icon={faChevronCircleRight} />
        </div>
      </div>
      <p>
        We will not share your email address with third-parties. For more
        information on how we use your information, see our{" "}
        <a
          href="https://img.com/privacypolicy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        .
      </p>
      <p className="spacer" />
    </form>
  );
};

export default Details1;
