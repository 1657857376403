import { useState, useEffect, useRef } from "react";

import { API_URL_DONATE, API_URL } from "../../utils/helpers";

import Alert from "../../components/Alert";

const Payment = ({ id, status }) => {
	const [order, setOrder] = useState({});
	const [alert, setAlert] = useState({
		type: "working",
		text: "",
	});

	const iframeRef = useRef(null);

	useEffect(() => {
		window.scrollTo(0, 0);
		init();
	}, []);

	useEffect(() => {
		const handler = (event) => {
			if (event && event.data) {
				const obj = JSON.parse(event.data);
				if (obj.status_id === 101) {
					setAlert({
						type: "working",
						text: "",
					});
					handleSubmit(obj);
				}
			}
		};

		window.addEventListener("message", handler);
		return () => window.removeEventListener("message", handler);
		// eslint-disable-next-line
	}, []);

	const init = async () => {
		const url = new URL(`${API_URL_DONATE}/`);
		const params = {
			f: "paymentInit",
			id,
			x: `113`,
		};
		url.search = new URLSearchParams(params);

		try {
			const response = await fetch(url, {
				method: "GET",
				cache: "no-store",
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setOrder({
					items: json.items,
					totals: json.totals,
					text: json.text,
					url: json.url,
				});
				setAlert({});
			} else {
				setAlert({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setAlert({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	const handleSubmit = async (obj) => {
		const url = new URL(`${API_URL}/?f=zeamsterpayment`);
		let data = new FormData();
		data.append("id", id || "0");
		data.append("zmster", JSON.stringify(obj) || "");

		try {
			const response = await fetch(url, {
				method: "POST",
				body: data,
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setAlert({});
				status(id, 1);
			} else {
				setAlert({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setAlert({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	return (
		<>
			{alert.type ? (
				<Alert data={alert} />
			) : (
				<div className="receipt">
					<h3>Payment</h3>

					<p>{order.text}</p>

					<table className="order">
						<thead>
							<tr>
								<th>Qty</th>
								<th className="left">Item</th>
								<th>Amount</th>
							</tr>
						</thead>
						<tbody>
							{order.items.map((item, index) => {
								return (
									<tr key={index}>
										<td className="center">
											<span>{item.qty}</span>
										</td>
										<td>
											<div>
												<span>{item.item}</span>
												<small>{item.subitem}</small>
											</div>
										</td>
										<td className="right">{item.price}</td>
									</tr>
								);
							})}
						</tbody>
						<tfoot>
							<tr>
								<td colSpan="2" className="right">
									TOTAL
								</td>
								<td className="right">{order.totals.total}</td>
							</tr>
						</tfoot>
					</table>

					<iframe
						ref={iframeRef}
						src={order.url}
						title="Payment Form"
						className="zeamster"
					/>

					<p className="spacer" />
				</div>
			)}
		</>
	);
};

export default Payment;
