import LinkBtn from "../../components/LinkBtn";

function Home() {
  return (
    <div
      className="button-links"
      style={{ marginTop: "25px", marginBottom: "35px" }}
    >
      <LinkBtn
        data={{ to: "/?type=existing", name: "I am an Existing Member" }}
      />

      <LinkBtn data={{ to: "/?type=new", name: "I am a New Member" }} />

      <LinkBtn
        data={{ to: "/?type=twenties", name: "I am a New Member aged 21-30" }}
      />

      <LinkBtn
        data={{
          to: "/?type=junior",
          name: "I am a Junior Member (20 or younger)",
        }}
      />
    </div>
  );
}

export default Home;
