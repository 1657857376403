import { Routes, Route, BrowserRouter as Router } from "react-router-dom";

import Enter from "./Enter";
import Returning from "./Returning";
import Home from "./Home";
import Quest1 from "./Quest1";
import Quest2 from "./Quest2";
import Quest3 from "./Quest3";
import Quest4 from "./Quest4";
import Quest5 from "./Quest5";
import Quest6 from "./Quest6";
import Quest7 from "./Quest7";
import Quest8 from "./Quest8";
import Submit from "./Submit";

function VKScholarship({ code, cares }) {
  return (
    <Router basename="/vkscholarship">
      <Routes>
        <Route path="/enter" element={<Enter cares={cares} />} />
        <Route path="/returning/:code" element={<Returning />} />
        <Route path="/quest1" element={<Quest1 cares={cares} />} />
        <Route path="/quest2" element={<Quest2 cares={cares} />} />
        <Route path="/quest3" element={<Quest3 cares={cares} />} />
        <Route path="/quest4" element={<Quest4 cares={cares} />} />
        <Route path="/quest5" element={<Quest5 cares={cares} />} />
        <Route path="/quest6" element={<Quest6 cares={cares} />} />
        <Route path="/quest7" element={<Quest7 cares={cares} />} />
        <Route path="/quest8" element={<Quest8 cares={cares} />} />
        <Route path="/submit" element={<Submit cares={cares} />} />
        <Route path="/" element={<Home cares={cares} />} />
      </Routes>
    </Router>
  );
}

export default VKScholarship;
