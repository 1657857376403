import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { API_URL_VKS as API_URL, getObjFromLS } from "../../utils/helpers";

import Alert from "../../components/Alert";
import Input from "../../components/Input";
import InputFile from "../../components/InputFile";
import Msg from "../../components/Msg";
import Submit from "../../components/Submit";
import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

function Quest5({ cares }) {
  const [user, setUser] = useState({});
  const [errors, setErrors] = useState([]);
  const [names, setNames] = useState({});
  const [msg, setMsg] = useState({});
  const [alert, setAlert] = useState({
    type: "working",
    text: "",
  });

  const formElement = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    if (getObjFromLS("scholarship")) {
      const obj = getObjFromLS("scholarship");
      setUser({
        token: obj.token,
        id: obj.id,
        email: obj.email,
      });

      init(obj.token);
    } else {
      navigate("/enter");
    }
  }, []);

  const init = async (token) => {
    const url = new URL(`${API_URL}/admin/stepInit`);
    const params = { token, step: "quest5" };
    url.search = new URLSearchParams(params);

    try {
      const response = await fetch(url, {
        method: "GET",
        cache: "no-store",
      });
      const json = await response.json();
      if (json && json.resp === 1) {
        setNames((names) => ({ ...names, ...json }));

        setAlert({});
      } else {
        setAlert({
          type: "error",
          text: "An error occurred accessing your application.",
        });
      }
    } catch (error) {
      setAlert({
        type: "error",
        text: "An error occurred accessing your application.",
      });
    }
  };

  const handleUpdate = (name, value) => {
    setNames((names) => ({ ...names, [name]: value ? value : "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors([]);
    setMsg({
      type: "working",
      text: "",
    });

    const url = new URL(`${API_URL}/admin/quest5`);
    let formData = new FormData();
    Object.entries(names).forEach(([key, value]) =>
      formData.append(key, value)
    );
    formData.append("token", user.token);

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });
      const json = await response.json();
      if (json && json.resp === 1) {
        navigate("/");
      } else {
        setErrors(json.fields);
        setMsg({
          type: "error",
          text: json.text,
        });
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      });
    }
  };

  return (
    <>
      {alert.type ? (
        <Alert data={alert} />
      ) : (
        <form
          method="post"
          action="/"
          onSubmit={(e) => handleSubmit(e)}
          ref={formElement}
        >
          <h2>Section #5 - Personal References</h2>

          <Link to="/">Back to Home</Link>

          <p>
            As a part of your application for the Victoria Kingdom scholarship,
            please provide three (3) personal references on the form attached.
            These references can be from a teacher, school administrator, church
            or religious leader, employer, community/organization leader, and
            the persons selected should be able to speak about you as a person
            and your character, work ethic, and attitude.
          </p>

          <p>
            A letter or recommedation is required from each of your personal
            references. please download{" "}
            <a
              href="https://thchcc.qnewmedia.com/k-assets/VKScholarship-Recommendation-Letter.pdf"
              download
            >
              this document
            </a>{" "}
            as an example of the information required by the committee.
          </p>

          <p className="line" />
          <h3>Personal Reference #1</h3>

          <div className="two">
            <div>
              <Input
                type="text"
                label="Name"
                req={true}
                name="name1"
                value={names.name1}
                update={handleUpdate}
                errors={errors}
              />
            </div>

            <div>
              <Input
                type="text"
                label="How do you know them"
                req={true}
                name="know1"
                value={names.know1}
                update={handleUpdate}
                errors={errors}
              />
            </div>
          </div>

          <div className="two">
            <div>
              <Input
                type="text"
                label="Email Address"
                req={true}
                name="email1"
                value={names.email1}
                update={handleUpdate}
                errors={errors}
              />
            </div>
            <div>
              <Input
                type="text"
                label="Contact Phone"
                req={true}
                name="phone1"
                value={names.phone1}
                update={handleUpdate}
                errors={errors}
              />
            </div>
          </div>

          <p>
            Please upload your letter of recommendation from this reference.
          </p>

          <div className="one">
            <InputFile
              label="Upload PDF"
              req={true}
              name="pdf1"
              value={names.pdf1}
              update={handleUpdate}
              errors={errors}
            />
          </div>

          {names.essay && (
            <p>
              <a href={names.essay} target="_blank" rel="noopener">
                View Uploaded Document
              </a>
            </p>
          )}

          <p className="line" />
          <h3>Personal Reference #2</h3>

          <div className="two">
            <div>
              <Input
                type="text"
                label="Name"
                req={true}
                name="name2"
                value={names.name2}
                update={handleUpdate}
                errors={errors}
              />
            </div>

            <div>
              <Input
                type="text"
                label="How do you know them"
                req={true}
                name="know2"
                value={names.know2}
                update={handleUpdate}
                errors={errors}
              />
            </div>
          </div>

          <div className="two">
            <div>
              <Input
                type="text"
                label="Email Address"
                req={true}
                name="email2"
                value={names.email2}
                update={handleUpdate}
                errors={errors}
              />
            </div>
            <div>
              <Input
                type="text"
                label="Contact Phone"
                req={true}
                name="phone2"
                value={names.phone2}
                update={handleUpdate}
                errors={errors}
              />
            </div>
          </div>

          <p>
            Please upload your letter of recommendation from this reference.
          </p>

          <div className="one">
            <InputFile
              label="Upload PDF"
              req={true}
              name="pdf2"
              value={names.pdf2}
              update={handleUpdate}
              errors={errors}
            />
          </div>

          <p className="line" />
          <h3>Personal Reference #3</h3>

          <div className="two">
            <div>
              <Input
                type="text"
                label="Name"
                req={true}
                name="name3"
                value={names.name3}
                update={handleUpdate}
                errors={errors}
              />
            </div>

            <div>
              <Input
                type="text"
                label="How do you know them"
                req={true}
                name="know3"
                value={names.know3}
                update={handleUpdate}
                errors={errors}
              />
            </div>
          </div>

          <div className="two">
            <div>
              <Input
                type="text"
                label="Email Address"
                req={true}
                name="email3"
                value={names.email3}
                update={handleUpdate}
                errors={errors}
              />
            </div>
            <div>
              <Input
                type="text"
                label="Contact Phone"
                req={true}
                name="phone3"
                value={names.phone3}
                update={handleUpdate}
                errors={errors}
              />
            </div>
          </div>

          <p>
            Please upload your letter of recommendation from this reference.
          </p>

          <div className="one">
            <InputFile
              label="Upload PDF"
              req={true}
              name="pdf3"
              value={names.pdf3}
              update={handleUpdate}
              errors={errors}
            />
          </div>

          <div className="msg-submit">
            {msg.type && <Msg data={msg} />}

            <div
              className={msg.type === "working" ? "hidden" : "submit-container"}
            >
              <Submit
                name="Submit Section #5"
                icon={faChevronCircleRight}
                cares={cares}
              />
            </div>
          </div>
          <p>
            We will not share your email address with third-parties. For more
            information on how we use your information, see our{" "}
            <a
              href="https://img.com/privacypolicy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            .
          </p>
          <p className="spacer" />
        </form>
      )}
    </>
  );
}

export default Quest5;
