import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  API_URL_VKS as API_URL,
  getObjFromLS,
  setObjToLS,
} from "../../utils/helpers";

import Radios from "../../components/Radios";
import Input from "../../components/Input";
import Msg from "../../components/Msg";
import Submit from "../../components/Submit";
import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

function Home({ cares }) {
  const [errors, setErrors] = useState([]);
  const [names, setNames] = useState({});
  const [msg, setMsg] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (getObjFromLS("scholarship")) {
      const obj = getObjFromLS("scholarship");
      navigate("/");
    }
  }, []);

  const handleUpdate = (name, value) => {
    setNames((names) => ({ ...names, [name]: value ? value : "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors([]);
    setMsg({
      type: "working",
      text: "",
    });

    const url = new URL(`${API_URL}/enter`);
    let data = new FormData();
    Object.entries(names).forEach(([key, value]) => data.append(key, value));

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
      });
      const json = await response.json();
      if (json && json.resp === 1) {
        setMsg({});
        setObjToLS("scholarship", {
          token: json.token,
          id: json.id,
          email: json.email,
        });
        navigate(json.navigate);
      } else if (json && json.resp === 2) {
        setMsg({
          type: "success",
          text: json.text,
        });
      } else {
        setErrors(json.fields);
        setMsg({
          type: "error",
          text: json.text,
        });
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      });
    }
  };

  return (
    <>
      <h3>Victoria Kingdom Scholarship Applicant Information & Requirements</h3>

      <h4>General Information</h4>
      <ul
        style={{ marginTop: "15px", marginLeft: "20px", marginBottom: "15px" }}
      >
        <li>
          The Victoria Kingdom Scholarship award is $2,500.00 per year and is
          renewable for three additional years if the recipient maintains a 2.75
          or above GPA. The total amount awarded to any scholarship winner shall
          not to exceed $10,000.00 for the four years. Renewal information and
          criteria will be provided to the winners after their selection as a
          Victoria Kingdom Scholar has been made.
        </li>
        <li>
          Having an interest in, working or worked in, or planning a career
          related to golf will be a positive consideration.
        </li>
        <li>
          All applications will be forwarded to the scholarship committee for
          consideration.
        </li>
        <li>
          The scholarship committee will make their recommendation of
          scholarship recipients to the Diversity Equity & Inclusion committee
        </li>
      </ul>

      <h4>Application Timeline</h4>
      <ul
        style={{ marginTop: "15px", marginLeft: "20px", marginBottom: "15px" }}
      >
        <li>Application deadline: March 5, 2023</li>
        <li>Finalist Interviews: April 1 - May 15, 2023</li>
        <li>Final Transcripts: Due on May 31, 2023</li>
        <li>Scholarship Awards: June 5-9, 2023</li>
      </ul>

      <h4>Requirements</h4>
      <ul
        style={{ marginTop: "15px", marginLeft: "20px", marginBottom: "15px" }}
      >
        <li>Must reside in either Palm Beach or Martin County</li>
        <li>Must be of Black/African American or Hispanic/LatinX</li>
        <li>Graduating from an accredited high school in Spring, 2023</li>
        <li>
          Student must provide transcript with Grade Point Average of 3.0 for
          initial application
        </li>
        <li>
          Admitted to accredited college or university for Fall 2023 semester.
          Proof of acceptance and commitment to attend the college or university
          is required before scholarship funds will be disbursed.
        </li>
      </ul>

      <h3>Victoria Kingdom Scholarship Application</h3>

      <form method="post" action="/" onSubmit={(e) => handleSubmit(e)}>
        <div style={{ marginTop: "10px" }}>
          <Radios
            name="entry"
            hdg="First visit or returning to complete a started application?"
            update={handleUpdate}
            data={[
              {
                value: "first",
                label:
                  "This is my first time starting the application for 2023.",
              },
              {
                value: "returning",
                label:
                  "I am returning to update my application for 2023 that I previously started.",
              },
            ].map((item) => {
              return {
                value: item.value,
                label: item.label,
                checked: names.entry === item.value ? true : false,
              };
            })}
            cares={cares}
          />
        </div>

        <div className="two">
          <div>
            <Input
              type="text"
              label="Email Address"
              req={true}
              name="email"
              value={names.email}
              update={handleUpdate}
              errors={errors}
            />
          </div>
          <div className="empty" />
        </div>

        <div className="msg-submit">
          {msg.type && <Msg data={msg} />}

          <div
            className={msg.type === "working" ? "hidden" : "submit-container"}
          >
            <Submit name="Continue" icon={faChevronCircleRight} cares={cares} />
          </div>
        </div>
        <p>
          We will not share your email address with third-parties. For more
          information on how we use your information, see our{" "}
          <a
            href="https://img.com/privacypolicy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          .
        </p>
        <p className="spacer" />
      </form>
    </>
  );
}

export default Home;
