import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faCheckCircle } from "@fortawesome/pro-light-svg-icons";

function Tr({ data }) {
  return (
    <tr>
      <td className="center">
        <FontAwesomeIcon
          icon={data.completed ? faCheckCircle : faTimesCircle}
          size="lg"
          style={{ color: data.completed ? "var(--success)" : "var(--error)" }}
        />
      </td>
      <td className="left">
        <Link to={`/${data.link}`}>{data.name}</Link>
      </td>
      <td className="center">{data.updated}</td>
    </tr>
  );
}

export default Tr;
