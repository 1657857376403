import React from "react";

import "../Input/styles.css";

function isFieldError(array, val) {
	return array.includes(val);
}

const InputUncontrolled = ({
	type,
	label,
	note = "",
	req,
	id,
	name,
	value,
	errors,
}) => {
	return (
		<div className="input">
			<label htmlFor={id} className={!label ? "hidden" : ""}>
				{`${label}`} {req ? <span className="error">*</span> : null}
			</label>
			<input
				type={type}
				name={name}
				id={id}
				autoComplete="off"
				className={isFieldError(errors, id) ? "error-border" : ""}
			/>
			<small className={note ? "" : "hidden"}>{note}</small>
		</div>
	);
};

export default InputUncontrolled;
