import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";

import Contact from "./pages/Contact";
import Subscribe from "./pages/Subscribe";
import Hospitality from "./pages/Hospitality";
import VKScholarship from "./pages/VKScholarship";
import CoutoureClub from "./pages/CoutoureClub";
import JrCoutoureClub from "./pages/JrCoutoureClub";
import AmbassadorsClub from "./pages/AmbassadorsClub";
import BFCSignup from "./pages/BFCSignup";
import BFCCharities from "./pages/BFCCharities";
/* still authorize.net import BFCDonate from "./pages/BFCDonate"; */
import BFCStandings from "./pages/BFCStandings";
import BFCReport from "./pages/BFCReport";
import WLFTickets from "./pages/WLFTickets";
import KickoffTickets from "./pages/KickoffTickets";
import Donate from "./pages/Donate";

import Alert from "./components/Alert";

/* uncomment for testing locally 
    contact, subscribe, donate, hospitality, coutoureclub, jrcoutoureclub, ambassadorsclub, 
		wlftickets, bfcsignup, bfccharities, bfcdonate, bfcstandings, bfcreport, 
    vkscholarship, kickofftickets,

window.thcform2Settings = {
	id: "donate",
	subject: "Hospitality",
	cares: true,
	code: "CTC",
	closed: false,
	disabled: false,
};
*/

if (document.getElementById("thcform2-root")) {
	const root = ReactDOM.createRoot(document.getElementById("thcform2-root"));

	let element = (
		<Alert data={{ type: "error", text: "An error has occurred." }} />
	);

	if (window.thcform2Settings) {
		const { id, subject, cares, code, closed, disabled } =
			window.thcform2Settings;

		if (id === "contact") {
			element = <Contact cares={cares} />;
		} else if (id === "subscribe") {
			element = <Subscribe cares={cares} />;
		} else if (id === "hospitality") {
			element = <Hospitality subject={subject} />;
		} else if (id === "vkscholarship") {
			element = closed ? (
				<Alert
					data={{
						type: "error",
						text: "Applications for the Victoria Kingdom Scholarship are now closed.",
					}}
				/>
			) : (
				<VKScholarship cares={cares} />
			);
		} else if (id === "kickofftickets") {
			element = closed ? (
				<Alert
					data={{
						type: "error",
						text: "Kickoff Party online ordering is now closed, tickets can be purchased at the door.",
					}}
				/>
			) : (
				<KickoffTickets />
			);
		} else if (id === "donate") {
			element = closed ? (
				<Alert
					data={{
						type: "error",
						text: "Donations to The Cognizant Classic Cares are currently closed.",
					}}
				/>
			) : (
				<Donate cares={cares} />
			);
		} else if (id === "coutoureclub") {
			element = closed ? (
				<Alert
					data={{
						type: "error",
						text: "Coutoure Club registration is now closed.",
					}}
				/>
			) : (
				<CoutoureClub />
			);
		} else if (id === "jrcoutoureclub") {
			element = closed ? (
				<Alert
					data={{
						type: "error",
						text: "Junior Coutoure Club registration is now closed.",
					}}
				/>
			) : (
				<JrCoutoureClub />
			);
		} else if (id === "ambassadorsclub") {
			element = closed ? (
				<Alert
					data={{
						type: "error",
						text: "Ambassador’s Club registration is currently closed.",
					}}
				/>
			) : (
				<AmbassadorsClub code={code} />
			);
		} else if (id === "bfcsignup") {
			/*
				Registration for the 2025 Birdies for Children program will open on September 1st.
			*/
			element = closed ? (
				<Alert
					data={{
						type: "error",
						text: "2024 Birdies for Children Registration is now closed.",
					}}
				/>
			) : (
				<BFCSignup code={code} cares={cares} />
			);
		} else if (id === "bfccharities") {
			/*
				Donations to the 2025 Birdies for Children will open later this month.
			*/

			element =
				closed && !window.sessionStorage.getItem("BFCAdmin") ? (
					<Alert
						data={{
							type: "error",
							text: "Donations to the 2024 Birdies for Children are now closed.",
						}}
					/>
				) : (
					<BFCCharities cares={cares} />
				);

			/* not active, still auth net
			} else if (id === "bfcdonate") {
			element = (
				<BFCDonate
					code={code}
					cares={cares}
					admin={window.sessionStorage.getItem("BFCAdmin") ? true : false}
				/>
			);
		*/
		} else if (id === "bfcstandings") {
			element = closed ? (
				<Alert
					data={{
						type: "error",
						text: "Standings for donations to the 2024 Birdies for Children will be available later this month.",
					}}
				/>
			) : (
				<BFCStandings cares={cares} />
			);
		} else if (id === "bfcreport") {
			element = <BFCReport code={code} cares={cares} />;
		} else if (id === "wlftickets") {
			element = closed ? (
				<Alert
					data={{
						type: "error",
						text: "Online ordering for tickets to the 2023 Women’s Leadership Forum are now closed.",
					}}
				/>
			) : (
				<WLFTickets />
			);
		}
	}

	root.render(<React.StrictMode>{element}</React.StrictMode>);
}
