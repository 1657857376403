import { useEffect, useState, useRef } from "react";
import { v4 as uuidv4 } from "uuid";

import { API_URL_AC, STATES, RACESAMBASS, SHIRTS } from "../../utils/helpers";

import Alert from "../../components/Alert";
import Select from "../../components/Select";
import Input from "../../components/Input";
import InputFile from "../../components/InputFile";
import Msg from "../../components/Msg";
import Submit from "../../components/Submit";

import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

const Details1 = ({ code, status }) => {
	const [errors, setErrors] = useState([]);
	const [names, setNames] = useState({});
	const [msg, setMsg] = useState({});
	const [alert, setAlert] = useState({
		type: "working",
		text: "",
	});

	const initRef = useRef(false);

	useEffect(() => {
		if (initRef.current) return;
		initRef.current = true;
		init();
	});

	const init = async () => {
		const url = new URL(`${API_URL_AC}/`);
		const params = {
			f: "details1Init",
			code,
			x: uuidv4(),
		};
		url.search = new URLSearchParams(params);

		try {
			const response = await fetch(url, {
				method: "GET",
				cache: "no-store",
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setNames({
					...json,
				});
				setAlert({});
			} else {
				setAlert({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setAlert({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	const handleUpdate = (name, value) => {
		setNames((names) => ({ ...names, [name]: value ? value : "" }));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setErrors([]);
		setMsg({
			type: "working",
			text: "",
		});

		const url = new URL(`${API_URL_AC}/?f=details1`);
		let formData = new FormData();
		Object.entries(names).forEach(([key, value]) =>
			formData.append(key, value)
		);
		/* code & amount included in names payload */

		try {
			const response = await fetch(url, {
				method: "POST",
				body: formData,
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setMsg({});
				if (json.amt === 0) {
					status(json.id, 1);
				} else {
					status(json.id, 3);
				}
			} else {
				setErrors(json.fields);
				setMsg({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setMsg({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	return (
		<>
			{alert.type ? (
				<Alert data={alert} />
			) : (
				<form method="post" action="/" onSubmit={(e) => handleSubmit(e)}>
					<p>
						Please fill out the form below to submit your 2023-24 Ambassadors’
						Club registration. Tournament assets will be mailed or emailed to
						the following address and this address will be used in our
						Ambassador Directory.
					</p>
					<div className="two">
						<div>
							<Input
								type="text"
								label="First Name"
								req={true}
								name="fname"
								value={names?.fname}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
						<div>
							<Input
								type="text"
								label="Last Name"
								req={true}
								name="lname"
								value={names?.lname}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
					</div>

					<p className="line" />

					<div className="two">
						<div>
							<Input
								type="text"
								label="Address"
								req={true}
								name="address"
								value={names?.address}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
						<div>
							<Input
								type="text"
								label="Address 2"
								req={false}
								name="address2"
								value={names?.address2}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
					</div>
					<div className="three">
						<div>
							<Input
								type="text"
								label="City"
								req={true}
								name="city"
								value={names?.city}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
						<div className="small">
							<Select
								label="State"
								req={true}
								name="st"
								value={names?.st}
								data={STATES}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
						<div className="small">
							<Input
								type="text"
								label="Zip"
								req={true}
								name="zip"
								value={names?.zip}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
					</div>

					<p className="line" />

					<div className="three">
						<div>
							<Input
								type="text"
								label="Home Phone"
								req={false}
								name="phoneHome"
								value={names?.phoneHome}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
						<div>
							<Input
								type="text"
								label="Work Phone"
								req={true}
								name="phoneWork"
								value={names?.phoneWork}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
						<div>
							<Input
								type="text"
								label="Cell Number"
								req={true}
								name="phone"
								value={names?.phone}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
					</div>

					<div className="two">
						<div>
							<Input
								type="text"
								label="Email Address"
								req={true}
								name="email"
								value={names?.email}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
						<div>
							<Input
								type="text"
								label="Fax Number"
								req={false}
								name="fax"
								value={names?.fax}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
					</div>

					<div className="two">
						<div>
							<Input
								type="text"
								label="Company"
								req={true}
								name="company"
								value={names?.company}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
						<div>
							<Input
								type="text"
								label="Title"
								req={false}
								name="title"
								value={names?.title}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
					</div>

					<div>
						<div>
							<Input
								type="text"
								label="Assistant Name & Contact Information"
								note=""
								req={false}
								name="assistant"
								value={names?.assistant}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
					</div>

					<p className="line" />

					<div className="one">
						<div>
							<Select
								label="Race/Ethnicity"
								req={true}
								name="race"
								value={names?.race}
								data={RACESAMBASS}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
					</div>

					<p>
						Disclaimer: “Answering this question is solely for the purpose of
						collecting statistical data. Your individual information will not be
						shared with anyone.”
					</p>

					<p className="line" />

					<div className="three">
						<div>
							<Select
								label="Shirt Size"
								req={true}
								name="shirt"
								value={names?.shirt}
								data={SHIRTS}
								update={handleUpdate}
								errors={errors}
							/>
						</div>

						<div>
							<Input
								type="text"
								label="Waist Size"
								req={true}
								name="waist"
								value={names?.waist}
								update={handleUpdate}
								errors={errors}
							/>
						</div>

						<div>
							<Select
								label="Outwear/Jacket Size"
								req={true}
								name="outerwear"
								value={names?.outerwear}
								data={SHIRTS}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
					</div>

					<div>
						<Input
							type="text"
							label="Golf Handicap"
							req={true}
							name="handicap"
							value={names?.handicap}
							update={handleUpdate}
							errors={errors}
						/>
					</div>

					{names.code !== "new" && (
						<div>
							<div>
								<Input
									type="text"
									label="Do you know of anyone who would be interested in joining our club?"
									note="If so, please provide their name, email address and cell number."
									req={false}
									name="interested"
									value=""
									update={handleUpdate}
									errors={errors}
								/>
							</div>
						</div>
					)}

					<p className="line" />

					<div className="one">
						<InputFile
							label={`Directory Picture (${
								names.code === "new" ? "required for new members" : "optional"
							})`}
							req={names.code === "new" ? true : false}
							name="jpeg"
							value={names?.jpeg}
							update={handleUpdate}
							errors={errors}
						/>
					</div>

					<p>
						Please upload a jpeg or png that is high-resolution (selfie from
						phone is fine with a minimum of 300 DPI).
					</p>

					<div className="msg-submit">
						{msg.type && <Msg data={msg} />}

						<div
							className={msg.type === "working" ? "hidden" : "submit-container"}
						>
							<Submit name="Continue" icon={faChevronCircleRight} />
						</div>
					</div>
					<p>
						We will not share your email address with third-parties. For more
						information on how we use your information, see our{" "}
						<a
							href="https://img.com/privacypolicy/"
							target="_blank"
							rel="noopener noreferrer"
						>
							Privacy Policy
						</a>
						.
					</p>
					<p className="spacer" />
				</form>
			)}
		</>
	);
};

export default Details1;
