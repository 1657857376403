import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  API_URL_VKS as API_URL,
  STATES,
  getObjFromLS,
} from "../../utils/helpers";

import Alert from "../../components/Alert";
import Input from "../../components/Input";
import Select from "../../components/Select";
import Checkbox from "../../components/Checkbox";
import Msg from "../../components/Msg";
import Submit from "../../components/Submit";
import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

function Quest1({ cares }) {
  const [user, setUser] = useState({});
  const [errors, setErrors] = useState([]);
  const [names, setNames] = useState({});
  const [msg, setMsg] = useState({});
  const [alert, setAlert] = useState({
    type: "working",
    text: "",
  });

  const formElement = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    if (getObjFromLS("scholarship")) {
      const obj = getObjFromLS("scholarship");
      setUser({
        token: obj.token,
        id: obj.id,
        email: obj.email,
      });

      init(obj.token);
    } else {
      navigate("/enter");
    }
  }, []);

  const init = async (token) => {
    const url = new URL(`${API_URL}/admin/stepInit`);
    const params = { token, step: "quest1" };
    url.search = new URLSearchParams(params);

    try {
      const response = await fetch(url, {
        method: "GET",
        cache: "no-store",
      });
      const json = await response.json();
      if (json && json.resp === 1) {
        setNames((names) => ({ ...names, ...json }));

        setAlert({});
      } else {
        setAlert({
          type: "error",
          text: "An error occurred accessing your application.",
        });
      }
    } catch (error) {
      setAlert({
        type: "error",
        text: "An error occurred accessing your application.",
      });
    }
  };

  const handleUpdate = (name, value) => {
    if (name === "same") {
      const checked = !names.same;
      setNames((names) => ({
        ...names,
        [name]: checked,
        parentAddress: names.address,
        parentAddress2: names.address2,
        parentCity: names.city,
        parentSt: names.st,
        parentZip: names.zip,
      }));

      let selectedIndex = 0;
      STATES.forEach((state, index) => {
        if (state.value === names.st) {
          selectedIndex = index;
        }
      });

      formElement.current.parentSt.selectedIndex = selectedIndex;
    } else if (name === "same2") {
      const checked = !names.same2;
      setNames((names) => ({
        ...names,
        [name]: checked,
        parent2Address: names.address,
        parent2Address2: names.address2,
        parent2City: names.city,
        parent2St: names.st,
        parent2Zip: names.zip,
      }));

      let selectedIndex = 0;
      STATES.forEach((state, index) => {
        if (state.value === names.st) {
          selectedIndex = index;
        }
      });

      formElement.current.parent2St.selectedIndex = selectedIndex;
    } else {
      setNames((names) => ({ ...names, [name]: value ? value : "" }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors([]);
    setMsg({
      type: "working",
      text: "",
    });

    const url = new URL(`${API_URL}/admin/quest1`);
    let formData = new FormData();
    Object.entries(names).forEach(([key, value]) =>
      formData.append(key, value)
    );
    formData.append("token", user.token);

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });
      const json = await response.json();
      if (json && json.resp === 1) {
        navigate("/");
      } else {
        setErrors(json.fields);
        setMsg({
          type: "error",
          text: json.text,
        });
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      });
    }
  };

  return (
    <>
      {alert.type ? (
        <Alert data={alert} />
      ) : (
        <form
          method="post"
          action="/"
          onSubmit={(e) => handleSubmit(e)}
          ref={formElement}
        >
          <h2>Section #1 - Applicant Details</h2>

          <Link to="/">Back to Home</Link>

          <h3 style={{ marginTop: "15px" }}>Applicant Information</h3>

          <div className="two">
            <div>
              <Input
                type="text"
                label="First Name"
                req={true}
                name="fname"
                value={names.fname}
                update={handleUpdate}
                errors={errors}
              />
            </div>

            <div>
              <Input
                type="text"
                label="Last Name"
                req={true}
                name="lname"
                value={names.lname}
                update={handleUpdate}
                errors={errors}
              />
            </div>
          </div>

          <div className="two">
            <div>
              <Input
                type="text"
                label="Email Address"
                req={true}
                name="email"
                value={names.email}
                update={handleUpdate}
                errors={errors}
              />
            </div>
            <div>
              <Input
                type="text"
                label="Confirm Email"
                req={true}
                name="confirm"
                value={names.confirm}
                update={handleUpdate}
                errors={errors}
              />
            </div>
          </div>

          <div className="two">
            <div>
              <Input
                type="text"
                label="Home Phone"
                req={false}
                name="phoneHome"
                value={names.phoneHome}
                update={handleUpdate}
                errors={errors}
              />
            </div>

            <div>
              <Input
                type="text"
                label="Mobile Phone"
                req={true}
                name="phoneMobile"
                value={names.phoneMobile}
                update={handleUpdate}
                errors={errors}
              />
            </div>
          </div>

          <div className="one">
            <div>
              <Input
                type="text"
                label="Date of Birth (mm/dd/yyyy)"
                req={true}
                name="birthday"
                value={names.birthday}
                update={handleUpdate}
                errors={errors}
              />
            </div>
          </div>

          <p className="line" />

          <div className="two">
            <div>
              <Input
                type="text"
                label="Address"
                req={true}
                name="address"
                value={names.address}
                update={handleUpdate}
                errors={errors}
              />
            </div>
            <div>
              <Input
                type="text"
                label="Address 2"
                req={false}
                name="address2"
                value={names.address2}
                update={handleUpdate}
                errors={errors}
              />
            </div>
          </div>
          <div className="three">
            <div>
              <Input
                type="text"
                label="City"
                req={true}
                name="city"
                value={names.city}
                update={handleUpdate}
                errors={errors}
              />
            </div>
            <div className="small">
              <Select
                label="State"
                req={true}
                name="st"
                value={names.st}
                data={STATES}
                update={handleUpdate}
                errors={errors}
              />
            </div>
            <div className="small">
              <Input
                type="text"
                label="Zip"
                req={true}
                name="zip"
                value={names.zip}
                update={handleUpdate}
                errors={errors}
              />
            </div>
          </div>

          <p className="line" />
          <h3>Parent/Guardian Information</h3>

          <div className="two">
            <div>
              <Input
                type="text"
                label="Parent/Guardian Name"
                req={true}
                name="parentName"
                value={names.parentName}
                update={handleUpdate}
                errors={errors}
              />
            </div>
            <div>
              <Input
                type="text"
                label="Parent/Guardian Email"
                req={true}
                name="parentEmail"
                value={names.parentEmail}
                update={handleUpdate}
                errors={errors}
              />
            </div>
          </div>

          <div className="two">
            <div>
              <Input
                type="text"
                label="Parent/Guardian Home Phone"
                req={false}
                name="parentPhoneHome"
                value={names.parentPhoneHome}
                update={handleUpdate}
                errors={errors}
              />
            </div>

            <div>
              <Input
                type="text"
                label="Parent/Guardian Mobile Phone"
                req={true}
                name="parentPhoneMobile"
                value={names.parentPhoneMobile}
                update={handleUpdate}
                errors={errors}
              />
            </div>
          </div>

          <p className="line" />

          {!names.same && (
            <div>
              <Checkbox
                name="same"
                update={handleUpdate}
                data={[
                  {
                    value: false,
                    label:
                      "Check if parent/guardian address is same as applicant",
                  },
                ].map((item) => {
                  return {
                    value: item.value,
                    label: item.label,
                    checked: names.same,
                  };
                })}
                cares={cares}
              />
            </div>
          )}

          <div className="two">
            <div>
              <Input
                type="text"
                label="Address"
                req={true}
                name="parentAddress"
                value={names.parentAddress}
                update={handleUpdate}
                errors={errors}
              />
            </div>
            <div>
              <Input
                type="text"
                label="Address 2"
                req={false}
                name="parentAddress2"
                value={names.parentAddress2}
                update={handleUpdate}
                errors={errors}
              />
            </div>
          </div>

          <div className="three">
            <div>
              <Input
                type="text"
                label="City"
                req={true}
                name="parentCity"
                value={names.parentCity}
                update={handleUpdate}
                errors={errors}
              />
            </div>
            <div className="small">
              <Select
                label="State"
                req={true}
                name="parentSt"
                value={names.parentSt || ""}
                data={STATES}
                update={handleUpdate}
                errors={errors}
              />
            </div>
            <div className="small">
              <Input
                type="text"
                label="Zip"
                req={true}
                name="parentZip"
                value={names.parentZip}
                update={handleUpdate}
                errors={errors}
              />
            </div>
          </div>

          <p className="line" />
          <h3>Second Parent/Guardian Information</h3>

          <div className="two">
            <div>
              <Input
                type="text"
                label="Parent/Guardian Name"
                req={false}
                name="parent2Name"
                value={names.parent2Name}
                update={handleUpdate}
                errors={errors}
              />
            </div>
            <div>
              <Input
                type="text"
                label="Parent/Guardian Email"
                req={false}
                name="parent2Email"
                value={names.parent2Email}
                update={handleUpdate}
                errors={errors}
              />
            </div>
          </div>

          <div className="two">
            <div>
              <Input
                type="text"
                label="Parent/Guardian Home Phone"
                req={false}
                name="parent2PhoneHome"
                value={names.parent2PhoneHome}
                update={handleUpdate}
                errors={errors}
              />
            </div>

            <div>
              <Input
                type="text"
                label="Parent/Guardian Mobile Phone"
                req={false}
                name="parent2PhoneMobile"
                value={names.parent2PhoneMobile}
                update={handleUpdate}
                errors={errors}
              />
            </div>
          </div>

          <p className="line" />

          {!names.same2 && (
            <div>
              <Checkbox
                name="same2"
                update={handleUpdate}
                data={[
                  {
                    value: false,
                    label:
                      "Check if second parent/guardian address is same as applicant",
                  },
                ].map((item) => {
                  return {
                    value: item.value,
                    label: item.label,
                    checked: names.same2,
                  };
                })}
                cares={cares}
              />
            </div>
          )}

          <div className="two">
            <div>
              <Input
                type="text"
                label="Address"
                req={false}
                name="parent2Address"
                value={names.parent2Address}
                update={handleUpdate}
                errors={errors}
              />
            </div>
            <div>
              <Input
                type="text"
                label="Address 2"
                req={false}
                name="parent2Address2"
                value={names.parent2Address2}
                update={handleUpdate}
                errors={errors}
              />
            </div>
          </div>

          <div className="three">
            <div>
              <Input
                type="text"
                label="City"
                req={false}
                name="parent2City"
                value={names.parent2City}
                update={handleUpdate}
                errors={errors}
              />
            </div>
            <div className="small">
              <Select
                label="State"
                req={false}
                name="parent2St"
                value={names.parent2St || ""}
                data={STATES}
                update={handleUpdate}
                errors={errors}
              />
            </div>
            <div className="small">
              <Input
                type="text"
                label="Zip"
                req={false}
                name="parent2Zip"
                value={names.parent2Zip}
                update={handleUpdate}
                errors={errors}
              />
            </div>
          </div>

          <p className="line" />
          <h3>Student Academic Information</h3>

          <div className="one">
            <div>
              <Input
                type="text"
                label="High School Name"
                req={true}
                name="school"
                value={names.school}
                update={handleUpdate}
                errors={errors}
              />
            </div>
          </div>

          <div>
            <div>
              <Input
                type="text"
                label="High School Address"
                req={true}
                name="schoolAddress"
                value={names.schoolAddress}
                update={handleUpdate}
                errors={errors}
              />
            </div>
          </div>

          <div className="two">
            <div>
              <Input
                type="text"
                label="Graduation Year"
                req={true}
                name="graduation"
                value={names.graduation}
                update={handleUpdate}
                errors={errors}
              />
            </div>
            <div>
              <Input
                type="text"
                label="GPA"
                req={true}
                name="gpa"
                value={names.gpa}
                update={handleUpdate}
                errors={errors}
              />
            </div>
          </div>

          <div className="msg-submit">
            {msg.type && <Msg data={msg} />}

            <div
              className={msg.type === "working" ? "hidden" : "submit-container"}
            >
              <Submit
                name="Submit Section #1"
                icon={faChevronCircleRight}
                cares={cares}
              />
            </div>
          </div>
          <p>
            We will not share your email address with third-parties. For more
            information on how we use your information, see our{" "}
            <a
              href="https://img.com/privacypolicy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            .
          </p>
          <p className="spacer" />
        </form>
      )}
    </>
  );
}

export default Quest1;
