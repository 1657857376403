import { useState } from "react";
import { API_URL_JRCC, STATES, SIZESA, SIZESB } from "../../utils/helpers";
import Select from "../../components/Select";
import Input from "../../components/Input";
import Msg from "../../components/Msg";
import Submit from "../../components/Submit";
import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

const Details1 = ({ status }) => {
  const [errors, setErrors] = useState([]);
  const [names, setNames] = useState({});
  const [msg, setMsg] = useState({});

  const handleUpdate = (name, value) => {
    setNames((names) => ({ ...names, [name]: value ? value : "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors([]);
    setMsg({
      type: "working",
      text: "",
    });

    const url = new URL(`${API_URL_JRCC}/details1`);
    let data = new FormData();
    Object.entries(names).forEach(([key, value]) => data.append(key, value));

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
      });
      const json = await response.json();
      if (json && json.resp === 1) {
        setMsg({});
        status(json.id, 3);
      } else {
        setErrors(json.fields);
        setMsg({
          type: "error",
          text: json.text,
        });
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      });
    }
  };

  return (
    <form method="post" action="/" onSubmit={(e) => handleSubmit(e)}>
      <p>
        The Classic in the Palm Beaches Junior CouTOURe Club engages high school girls, aged
        14 - 18 years old, in charity involvement while enhancing leadership
        skills through a friendly and fun environment.
      </p>

      <p>
        As a Junior division of the CouTOURe Club, we provide members with
        opportunities to volunteer at charitable community activities through
        the year, to volunteer at The Classic in the Palm Beaches tournament, and to develop,
        network and maintain new friendships with both the Junior and CouTOURe
        Club members.
      </p>

      <p>
        Membership dues are $200.00 for the season which runs October 1 to June
        15th each year. For more information, please email or text us with any
        questions.
      </p>

      <div className="receipt" style={{ marginTop: "20px" }}>
        <table className="order">
          <thead>
            <tr>
              <th colSpan="2">Junior CC Co-Chairs</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="center" style={{ width: "50%" }}>
                Sara Misselhorn
                <br />
                (561) 889-1646
              </td>
              <td className="center" style={{ width: "50%" }}>
                Deborah Jaffe
                <br />
                (561) 373-4823
              </td>
            </tr>

            <tr>
              <td colSpan="2" className="center">
                <a href="mailto:juniorcc.coutoureclub@gmail.com">
                  juniorcc.coutoureclub@gmail.com
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="two">
        <div>
          <Input
            type="text"
            label="First Name"
            req={true}
            name="fname"
            value={names.fname}
            update={handleUpdate}
            errors={errors}
          />
        </div>

        <div>
          <Input
            type="text"
            label="Last Name"
            req={true}
            name="lname"
            value={names.lname}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <div className="two">
        <div>
          <Input
            type="text"
            label="Email Address"
            req={true}
            name="email"
            value={names.email}
            update={handleUpdate}
            errors={errors}
          />
        </div>
        <div>
          <Input
            type="text"
            label="Confirm Email"
            req={true}
            name="confirm"
            value={names.confirm}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <div className="three">
        <div>
          <Input
            type="text"
            label="Cell Number"
            req={true}
            name="phone"
            value={names.phone}
            update={handleUpdate}
            errors={errors}
          />
        </div>
        <div>
          <Input
            type="text"
            label="Birthday (mm/dd/yyyy)"
            req={true}
            name="birthday"
            value={names.birthday}
            update={handleUpdate}
            errors={errors}
          />
        </div>
        <div>
          <Input
            type="text"
            label="Hobbies"
            req={false}
            name="hobbies"
            value={names.hobbies}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <div className="two">
        <div>
          <Input
            type="text"
            label="School"
            req={true}
            name="school"
            value={names.school}
            update={handleUpdate}
            errors={errors}
          />
        </div>

        <div>
          <Select
            label="Grade 2022-23 School Year"
            req={true}
            name="grade"
            value={names.grade}
            data={[
              { value: "", name: "--" },
              { value: "9th", name: "9th" },
              { value: "10th", name: "10th" },
              { value: "11th", name: "11th" },
              { value: "12th", name: "12th" },
            ]}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <p className="line" />

      <div className="two">
        <div>
          <Input
            type="text"
            label="Parent First & Last Name"
            req={true}
            name="parent"
            value={names.parent}
            update={handleUpdate}
            errors={errors}
          />
        </div>
        <div>
          <Input
            type="text"
            label="Parent Cell Number"
            req={true}
            name="parentPhone"
            value={names.parentPhone}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <div className="two">
        <div>
          <Input
            type="text"
            label="Parent Email"
            req={true}
            name="parentEmail"
            value={names.parentEmail}
            update={handleUpdate}
            errors={errors}
          />
        </div>
        <div>
          <Input
            type="text"
            label="Confirm Parent Email"
            req={true}
            name="parentEmailConfirm"
            value={names.parentEmailConfirm}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <div className="three">
        <div>
          <Input
            type="text"
            label="Emergency Contact"
            req={true}
            name="emergencyContact"
            value={names.emergencyContact}
            update={handleUpdate}
            errors={errors}
          />
        </div>
        <div>
          <Input
            type="text"
            label="Emergency Cell Number"
            req={true}
            name="emergencyPhone"
            value={names.emergencyPhone}
            update={handleUpdate}
            errors={errors}
          />
        </div>
        <div>
          <Input
            type="text"
            label="Relationship to Contact"
            req={true}
            name="emergencyRelationship"
            value={names.emergencyRelationship}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <p className="line" />

      <div className="two">
        <div>
          <Input
            type="text"
            label="Address"
            req={true}
            name="address"
            value={names.address}
            update={handleUpdate}
            errors={errors}
          />
        </div>
        <div>
          <Input
            type="text"
            label="Address 2"
            req={false}
            name="address2"
            value={names.address2}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <div className="three">
        <div>
          <Input
            type="text"
            label="City"
            req={true}
            name="city"
            value={names.city}
            update={handleUpdate}
            errors={errors}
          />
        </div>
        <div className="small">
          <Select
            label="State"
            req={true}
            name="st"
            value={names.st}
            data={STATES}
            update={handleUpdate}
            errors={errors}
          />
        </div>
        <div className="small">
          <Input
            type="text"
            label="Zip"
            req={true}
            name="zip"
            value={names.zip}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <p className="line" />

      <div className="bottom-margin">
        <div>
          <Input
            type="text"
            label="How did you hear about the Junior CouTOURe Club?"
            note=""
            req={false}
            name="heard"
            value={names.heard}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <div className="bottom-margin">
        <div>
          <Input
            type="text"
            label="What attributes will you bring to the Junior CouTOURe Club?"
            note=""
            req={false}
            name="attributes"
            value={names.attributes}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <div className="bottom-margin">
        <div>
          <Input
            type="text"
            label="Do you have a mother or relative that would be interested in becoming a CC member?"
            note="If so, please provide their name, email address, cell number and birthdate including year."
            req={false}
            name="interested"
            value={names.interested}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <div className="bottom-margin">
        <div>
          <Input
            type="text"
            label="Do you know of anyone who would be interested in joining the Junior CouTOURe Club?"
            note="If so please provide their name, email address, cell number and birthdate including year."
            req={false}
            name="sponsoring"
            value={names.sponsoring}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <div>
        <div>
          <Input
            type="text"
            label="Please list other charitable organizations you are involved in and your role within the organization."
            note=""
            req={false}
            name="other"
            value={names.other}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <div className="three">
        <div>
          <Input
            type="text"
            label="Find me on Facebook at"
            req={false}
            name="facebook"
            value={names.facebook}
            update={handleUpdate}
            errors={errors}
          />
        </div>

        <div>
          <Input
            type="text"
            label="Find me on Instagram at"
            req={false}
            name="instagram"
            value={names.instagram}
            update={handleUpdate}
            errors={errors}
          />
        </div>

        <div>
          <Input
            type="text"
            label="Find me on Tik Tok at"
            req={false}
            name="tiktok"
            value={names.tiktok}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      {/*
      <div>
        <Radios
          name="help"
          hdg="Yes, I would like to help with a committee."
          req={false}
          update={handleUpdate}
          data={[
            {
              value: "Charities",
              label: "Charities",
            },
            {
              value: "Communications",
              label: "Communications",
            },
            {
              value: "Membership",
              label: "Membership",
            },
            {
              value: "Social",
              label: "Social",
            },
          ].map((item) => {
            return {
              value: item.value,
              label: item.label,
              checked: names.help === item.value ? true : false,
            };
          })}
        />
      </div>*/}

      <p className="line" />

      <h3>Please indicate your sizes in Lilly Pulitzer clothing.</h3>

      <div className="two">
        <div>
          <Select
            label="Tops in Sizes XXS - XL"
            req="true"
            name="size1"
            value=""
            data={SIZESA}
            update={handleUpdate}
            errors={errors}
          />
        </div>
        <div>
          <Select
            label="Tops in Sizes 00 - 16"
            req="true"
            name="size2"
            value=""
            data={SIZESB}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <div className="two">
        <div>
          <Select
            label="Dresses in Sizes XXS - XL"
            req="true"
            name="size3"
            value=""
            data={SIZESA}
            update={handleUpdate}
            errors={errors}
          />
        </div>
        <div>
          <Select
            label="Dresses in Sizes 00 - 16"
            req="true"
            name="size4"
            value=""
            data={SIZESB}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <div className="two">
        <div>
          <Select
            label="Shorts/Skorts/Pants in Sizes XXS - XL"
            req="true"
            name="size5"
            value=""
            data={SIZESA}
            update={handleUpdate}
            errors={errors}
          />
        </div>
        <div>
          <Select
            label="Shorts/Skorts/Pants in Sizes 00 - 16"
            req="true"
            name="size6"
            value=""
            data={SIZESB}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <div className="msg-submit">
        {msg.type && <Msg data={msg} />}

        <div className={msg.type === "working" ? "hidden" : "submit-container"}>
          <Submit name="Continue" icon={faChevronCircleRight} />
        </div>
      </div>
      <p>
        We will not share your email address with third-parties. For more
        information on how we use your information, see our{" "}
        <a
          href="https://img.com/privacypolicy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        .
      </p>
      <p className="spacer" />
    </form>
  );
};

export default Details1;
