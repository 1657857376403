import { useState } from "react";

import Entry from "./Entry";
import Details from "./Details";
import Payment from "./Payment";
import Receipt from "./Receipt";

const Donate = ({ cares }) => {
	const [id, setId] = useState("");
	const [status, setStatus] = useState(3);

	/*
    0 - error submitting payment
    1 - payment submitted successfully
    2 - donation info submitted, payment not yet submitted
    3 - donation info not yet submitted
    9 - tito's contest entry form
  */

	const handleStatus = (id = "", status) => {
		setId(id);
		setStatus(status);
	};

	return (
		<>
			{id && status === 1 ? (
				<Receipt id={id} />
			) : id && status === 2 ? (
				<Payment cares={cares} id={id} status={handleStatus} />
			) : status === 9 ? (
				<Entry cares={cares} />
			) : (
				<Details cares={cares} status={handleStatus} />
			)}
		</>
	);
};

export default Donate;
