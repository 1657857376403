import { useState } from "react";
import {
  API_URL_CC as API_URL,
  STATES,
  RACES,
  SIZESA,
  SIZESB,
} from "../../utils/helpers";
import Select from "../../components/Select";
import Input from "../../components/Input";
import InputFile from "../../components/InputFile";
import Msg from "../../components/Msg";
import Submit from "../../components/Submit";
import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

const Details1 = ({ status, type }) => {
  const [errors, setErrors] = useState([]);
  const [names, setNames] = useState({
    type,
  });
  const [msg, setMsg] = useState({});

  const handleUpdate = (name, value) => {
    setNames((names) => ({ ...names, [name]: value ? value : "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors([]);
    setMsg({
      type: "working",
      text: "",
    });

    const url = new URL(`${API_URL}/details1`);
    let data = new FormData();
    Object.entries(names).forEach(([key, value]) => data.append(key, value));

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
      });
      const json = await response.json();
      if (json && json.resp === 1) {
        setMsg({});
        status(json.id, 3);
      } else {
        setErrors(json.fields);
        setMsg({
          type: "error",
          text: json.text,
        });
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      });
    }
  };

  return (
    <form method="post" action="/" onSubmit={(e) => handleSubmit(e)}>
      <p>
        The CouTOURe Club is a group of dedicated women committed to building
        public awareness of The Classic in the Palm Beaches while increasing charitable
        contributions to benefit the children of our community.
      </p>

      <h3>Section 1 - Contact Information</h3>

      <div className="two">
        <div>
          <Input
            type="text"
            label="First Name"
            req={true}
            name="fname"
            value={names.fname}
            update={handleUpdate}
            errors={errors}
          />
        </div>

        <div>
          <Input
            type="text"
            label="Last Name"
            req={true}
            name="lname"
            value={names.lname}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>
      <div className="two">
        <div>
          <Input
            type="text"
            label="Email Address"
            req={true}
            name="email"
            value={names.email}
            update={handleUpdate}
            errors={errors}
          />
        </div>
        <div>
          <Input
            type="text"
            label="Confirm Email"
            req={true}
            name="confirm"
            value={names.confirm}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <div className="one">
        <div>
          <Input
            type="text"
            label="Cell Number"
            req={true}
            name="phone"
            value={names.phone}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <p className="line" />

      <div className="two">
        <div>
          <Input
            type="text"
            label="Address"
            req={true}
            name="address"
            value={names.address}
            update={handleUpdate}
            errors={errors}
          />
        </div>
        <div>
          <Input
            type="text"
            label="Address 2"
            req={false}
            name="address2"
            value={names.address2}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>
      <div className="three">
        <div>
          <Input
            type="text"
            label="City"
            req={true}
            name="city"
            value={names.city}
            update={handleUpdate}
            errors={errors}
          />
        </div>
        <div className="small">
          <Select
            label="State"
            req={true}
            name="st"
            value={names.st}
            data={STATES}
            update={handleUpdate}
            errors={errors}
          />
        </div>
        <div className="small">
          <Input
            type="text"
            label="Zip"
            req={true}
            name="zip"
            value={names.zip}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <p className="line" />

      <div className="two">
        <div>
          <Input
            type="text"
            label="Emergency Contact"
            req={true}
            name="emergencyContact"
            value={names.emergencyContact}
            update={handleUpdate}
            errors={errors}
          />
        </div>
        <div>
          <Input
            type="text"
            label="Emergency Cell Number"
            req={true}
            name="emergencyPhone"
            value={names.emergencyPhone}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>
      <div className="one">
        <div>
          <Input
            type="text"
            label="Relationship to Contact"
            req={true}
            name="emergencyRelationship"
            value={names.emergencyRelationship}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <p className="line" />

      <h3>Section 2 - Demographic Information</h3>

      <div className="one">
        <div>
          <Input
            type="text"
            label="Birthday (mm/dd/yyyy)"
            req={true}
            name="birthday"
            value={names.birthday}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <div className="two">
        <div>
          <Input
            type="text"
            label="Occupation"
            req={false}
            name="occupation"
            value={names.occupation}
            update={handleUpdate}
            errors={errors}
          />
        </div>

        <div>
          <Input
            type="text"
            label="Employer"
            req={false}
            name="employer"
            value={names.employer}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <div>
        <div>
          <Input
            type="text"
            label="Hobbies"
            req={false}
            name="hobbies"
            value={names.hobbies}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <div className="one">
        <div>
          <Select
            label="Race/Ethnicity"
            req={true}
            name="race"
            value=""
            data={RACES}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <p>
        Disclaimer: “Answering this question is solely for the purpose of
        collecting statistical data. Your individual information will not be
        shared with anyone and will not be used for any purpose related to your
        volunteer assignment.”
      </p>

      <p className="line" />

      <h3>Section 3 - Help Us Grow</h3>

      <div className="two">
        <div>
          <Input
            type="text"
            label="Find me on Facebook at"
            req={false}
            name="facebook"
            value={names.facebook}
            update={handleUpdate}
            errors={errors}
          />
        </div>

        <div style={{ marginBottom: "15px" }}>
          <Input
            type="text"
            label="Find me on Instagram at"
            req={false}
            name="instagram"
            value={names.instagram}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <div className="bottom-margin">
        <div>
          <Input
            type="text"
            label="Do you have a daughter that would be interested in becoming a Junior CouTOURe Club member?"
            note="If so, please provide their name, email address, cell number and birthdate including year."
            req={false}
            name="daughter"
            value={names.daughter}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      {type === "existing" && (
        <div className="bottom-margin">
          <div>
            <Input
              type="text"
              label="Do you know of anyone who would be interested in joining our club?"
              note="If so, please provide their name, email address and cell number."
              req={false}
              name="interested"
              value={names.interested}
              update={handleUpdate}
              errors={errors}
            />
          </div>
        </div>
      )}

      <div className={type === "new" ? "bottom-margin" : ""}>
        <div>
          <Input
            type="text"
            label="Do you know of any companies or individuals who would be interested in sponsoring the CouTOURe Club?"
            note="If so, please provide the contact name, phone number and email address."
            req={false}
            name="sponsoring"
            value={names.sponsoring}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      {type === "new" && (
        <div>
          <div>
            <Input
              type="text"
              label="Please provide the name or names of the CouTOURe Club member who referred you?"
              note=""
              req={true}
              name="referred"
              value={names.referred}
              update={handleUpdate}
              errors={errors}
            />
          </div>
        </div>
      )}

      <p className="line" />

      <h3>Section 4 - Sizes in Lilly Pulitzer Clothing</h3>

      <div className="two">
        <div>
          <Select
            label="Polo Style Tops in Sizes XXS - XL"
            req="true"
            name="size1"
            value=""
            data={SIZESA}
            update={handleUpdate}
            errors={errors}
          />
        </div>
        <div>
          <Select
            label="Polo Style Tops in Sizes 00 - 16"
            req="true"
            name="size2"
            value=""
            data={SIZESB}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <div className="two">
        <div>
          <Select
            label="Dresses in Sizes XXS - XL"
            req="true"
            name="size3"
            value=""
            data={SIZESA}
            update={handleUpdate}
            errors={errors}
          />
        </div>
        <div>
          <Select
            label="Dresses in Sizes 00 - 16"
            req="true"
            name="size4"
            value=""
            data={SIZESB}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <div className="two">
        <div>
          <Select
            label="Shorts/Skorts/Pants in Sizes XXS - XL"
            req="true"
            name="size5"
            value=""
            data={SIZESA}
            update={handleUpdate}
            errors={errors}
          />
        </div>
        <div>
          <Select
            label="Shorts/Skorts/Pants in Sizes 00 - 16"
            req="true"
            name="size6"
            value=""
            data={SIZESB}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <p className="line" />

      <h3>
        Section 5 - Directory Picture (
        {type === "existing" ? "optional" : "required"})
      </h3>

      <div className="one">
        <InputFile
          label="Directory Picture JPEG"
          req={type === "existing" ? false : true}
          name="jpeg"
          value={names.jpeg}
          update={handleUpdate}
          errors={errors}
        />
      </div>

      <p>
        Please upload a jpeg that is high-resolution (selfie from phone is fine
        with a minimum of 300 DPI).
      </p>

      <div className="msg-submit">
        {msg.type && <Msg data={msg} />}

        <div className={msg.type === "working" ? "hidden" : "submit-container"}>
          <Submit name="Continue" icon={faChevronCircleRight} />
        </div>
      </div>
      <p>
        We will not share your email address with third-parties. For more
        information on how we use your information, see our{" "}
        <a
          href="https://img.com/privacypolicy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        .
      </p>
      <p className="spacer" />
    </form>
  );
};

export default Details1;
