import React from "react";
import "./styles.css";

const Charity = ({ details }) => {
  return (
    <div className="charity">
      <img src={details.logo} alt={details.alt} />
      <div>
        <p className="address">
          <strong>{details.name}</strong>
          <span>{details.address1}</span>
          <span>{details.address2}</span>
          <span>Phone: {details.phone}</span>
          <span>
            Web:{" "}
            <a href={details.web} target="_blank" rel="noopener noreferrer">
              {details.webFormatted}
            </a>
          </span>
          <span className="total">Total Donations: {details.donations}</span>
        </p>
      </div>
    </div>
  );
};

export default Charity;
