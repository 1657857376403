import { useState, useEffect, useRef } from "react";

import { API_URL_DONATE, fmtCurrency } from "../../utils/helpers";

import DonateButton from "../../components/DonateButton";
import Input from "../../components/Input";
import Msg from "../../components/Msg";
import Submit from "../../components/Submit";
import Alert from "../../components/Alert";

import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

const Details = ({ cares, status }) => {
	const [errors, setErrors] = useState([]);
	const [names, setNames] = useState({
		id: "",
		checkbox: false,
	});
	const [total, setTotal] = useState(0);
	const [msg, setMsg] = useState({});
	const [alert] = useState({});

	const amounts = [
		{ value: 10, name: "$10" },
		{ value: 15, name: "$15" },
		{ value: 25, name: "$25" },
		{ value: 50, name: "$50" },
		{ value: 100, name: "$100" },
		{ value: 250, name: "$250" },
		{ value: 500, name: "$500" },
		{ value: "other", name: "Other" },
	];

	const formElement = useRef();

	const params = new Proxy(new URLSearchParams(window.location.search), {
		get: (searchParams, prop) => searchParams.get(prop),
	});

	useEffect(() => {
		if (params.ref) {
			handleUpdate("reference", params.ref);
			formElement.current.reference.value = params.ref;
		}
	}, []);

	const handleUpdate = (name, value) => {
		if (name === "different") {
			setNames((names) => ({ ...names, different: !names.different }));
		} else if (name === "amount" && !isNaN(value)) {
			const newAmt = 0 + value;
			setNames((names) => ({ ...names, [name]: value ? value : 0 }));
			setTotal(newAmt);
		} else if (name === "amount" && isNaN(value)) {
			const newAmt = 0;
			setNames((names) => ({ ...names, [name]: value ? value : "" }));
			setTotal(newAmt);
		} else if (name === "other") {
			if (!isNaN(value.replace("$", ""))) {
				const otherAmt = value.replace("$", "");
				const newAmt = 0 + Number(otherAmt);
				setNames((names) => ({
					...names,
					[name]: value ? Number(otherAmt) : 0,
				}));
				setTotal(newAmt);
			}
		} else {
			setNames((names) => ({ ...names, [name]: value ? value : "" }));
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setErrors([]);
		setMsg({
			type: "working",
			text: "",
		});

		const url = new URL(`${API_URL_DONATE}/?f=details`);

		let data = new FormData();
		Object.entries(names).forEach(([key, value]) => data.append(key, value));
		data.append("total", total || "0");

		try {
			const response = await fetch(url, {
				method: "POST",
				body: data,
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setMsg({});
				status(json.id, 2);
			} else {
				setErrors(json.fields);
				setMsg({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setMsg({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	return (
		<>
			{alert.type ? (
				<Alert data={alert} />
			) : (
				<form
					method="post"
					action="/"
					onSubmit={(e) => handleSubmit(e)}
					ref={formElement}
				>
					<h3>Select Donation Amount</h3>
					<div className="buttons">
						{amounts.map((item, index) => {
							return (
								<DonateButton
									key={index}
									data={item}
									click={handleUpdate}
									selected={names.amount === item.value ? true : false}
								/>
							);
						})}
					</div>
					<div className={names.amount === "other" ? "one" : "hidden"}>
						<Input
							type="text"
							label="Other Donation Amount"
							req={true}
							name="other"
							value={names.other}
							update={handleUpdate}
							errors={errors}
						/>
					</div>
					<p className="line" />
					<div className="two">
						<div>
							<Input
								type="text"
								label="First Name"
								req={true}
								name="fname"
								value={names.fname}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
						<div>
							<Input
								type="text"
								label="Last Name"
								req={true}
								name="lname"
								value={names.lname}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
					</div>
					<div className="two">
						<div>
							<Input
								type="text"
								label="Email Address"
								req={true}
								name="email"
								value={names.email}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
						<div>
							<Input
								type="text"
								label="Phone"
								req={false}
								name="phone"
								value={names.phone}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
					</div>
					<div className="one">
						<Input
							type="text"
							label="Reference"
							req={false}
							name="reference"
							value={names.notes}
							update={handleUpdate}
							errors={errors}
						/>
					</div>

					<p className="line" />

					<p>
						TOTAL AMOUNT TO CHARGE: <strong>{fmtCurrency(total)}</strong>
					</p>
					<div className="msg-submit">
						{msg.type && <Msg data={msg} />}

						<div
							className={msg.type === "working" ? "hidden" : "submit-container"}
						>
							<Submit
								name="Continue to Payment"
								icon={faChevronCircleRight}
								cares={cares}
							/>
						</div>
					</div>
					<p>
						We will not share your name or email address with third-parties.
					</p>
					{/*<p>
            Looking to enter the Tito’s giveaway without a donation?{" "}
            <button
              type="button"
              className="naked"
              onClick={() => status("", 9)}
              style={{
                background: "none",
                appearance: "none",
                border: "none",
                fontSize: "inherit",
                color: "var(--blue)",
                cursor: "pointer",
                outline: "none",
              }}
            >
              Click here.
            </button>
            </p>*/}
					<p className="spacer" />
				</form>
			)}
		</>
	);
};

export default Details;
