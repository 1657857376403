import React from "react";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons";

const Notice = ({ data }) => {
  return (
    <div className={`notice`}>
      <FontAwesomeIcon icon={faExclamationCircle} size="2x" />
      <span>{data.text}</span>
    </div>
  );
};

export default Notice;
