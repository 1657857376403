import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

import { API_URL_BFC } from "../../utils/helpers";

import ButtonLink from "../../components/ButtonLink";
import Alert from "../../components/Alert";

const Thanks = ({ id }) => {
	const [order, setOrder] = useState({});
	const [alert, setAlert] = useState({
		type: "working",
		text: "",
	});

	useEffect(() => {
		window.scrollTo(0, 0);
		init(id);
	}, [id]);

	const init = async (id) => {
		const url = new URL(`${API_URL_BFC}/`);
		const params = {
			f: "signupThanksInit",
			id,
			x: uuidv4(),
		};
		url.search = new URLSearchParams(params);

		try {
			const response = await fetch(url, {
				method: "GET",
				cache: "no-store",
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setOrder({
					id: json.id,
					date: json.date,
					name: json.name,
					email: json.email,
					text: json.text,
				});
				setAlert({});
			} else {
				setAlert({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setAlert({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	return (
		<>
			{alert.type ? (
				<Alert data={alert} />
			) : (
				<div className="receipt">
					<p>
						Thank you for registering your charitable organization in the 2024
						Birdies for Children Program.
					</p>

					{/*<p>
            Your organization will be live and ready to receive donations within
            3 business days.
          </p>*/}

					<table className="submitted">
						<tbody>
							<tr>
								<th className="right">Charity Name:</th>
								<td className="left">{order.name}</td>
							</tr>
							<tr>
								<th className="right">Email Address:</th>
								<td className="left">{order.email}</td>
							</tr>
							<tr>
								<th className="right">Date Submitted:</th>
								<td className="left">{order.date}</td>
							</tr>
						</tbody>
					</table>

					<p className="line" />

					<p>
						Once your registration has been processed, you will receive a
						confirmation email that will include:
					</p>

					<ul className="list">
						<li>
							Personal web link that can be distributed by email, social media,
							newsletters and more and will ensure donors are providing 100% of
							their pledge to the designated charity.
						</li>
						<li>
							Paper pledge form that can be printed and used for in-person
							solicitations.
						</li>
					</ul>

					<p>
						Some Key Facts about American Honda’s Birdies for Children Program.
					</p>

					<ul className="list">
						<li>
							Birdies for Children Program has raised a total of $20 million
							since its inception at The Classic in The Palm Beaches in 2010.
						</li>
						<li>
							The 2023 program supported 85 Charitable Organizations giving back
							to these organizations a total of $3,710,821.
						</li>
						<li>
							American Honda Birdies for Children program is open to any
							registered 501(c)3 organization in the United States.
						</li>
						<li>
							The Classic in The Palm Beaches contribution has no cap and each
							dollar raised will receive 5% match from the proceeds of the
							tournament.
						</li>
						<li>
							The Birdies for Children program runs through Wednesday, February
							22nd at 9 pm.
						</li>
					</ul>

					<p className="line" />

					<p>
						Please feel free to contact me with any questions you have about the
						program, and I wish you great success in the 2024 Birdies for
						Children program.
					</p>

					<p>
						Dana Johnson, Community Relations Director
						<br />
						(561) 799-4607
						<br />
						<a href="mailto:dana.johnson@img.com">dana.johnson@img.com</a>
					</p>

					<p className="line" />

					<div className="button-links">
						{[
							{
								name: "Charity Handbook",
								url: "https://theclassic.wpengine.com/k-assets/2024-BFC-Charity-Handbook.pdf",
							},
							{
								name: "Pledge Form",
								url: "https://theclassic.wpengine.com/k-assets/2024-BFC-Pledge-Form.pdf",
							},
						].map((item, index) => {
							return <ButtonLink key={index} data={item} />;
						})}
					</div>

					<p className="spacer" />
				</div>
			)}
		</>
	);
};

export default Thanks;
