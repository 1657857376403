import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { API_URL_VKS as API_URL, setObjToLS } from "../../utils/helpers";

import Alert from "../../components/Alert";

function Returning() {
  const [alert, setAlert] = useState({
    type: "working",
    text: "",
  });

  const { code } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (code) {
      init(code);
    } else {
      navigate("/enter");
    }
  }, []);

  const init = async (code) => {
    const url = new URL(`${API_URL}/returningInit`);
    const params = { code };
    url.search = new URLSearchParams(params);

    try {
      const response = await fetch(url, {
        method: "GET",
        cache: "no-store",
      });
      const json = await response.json();
      if (json && json.resp === 1) {
        setAlert({});
        setObjToLS("scholarship", {
          token: json.token,
          id: json.id,
          email: json.email,
        });
        navigate(json.navigate);
      } else {
        setAlert({
          type: "error",
          text: json.text,
        });
      }
    } catch (error) {
      setAlert({
        type: "error",
        text: "An error occurred.",
      });
    }
  };

  return <>{alert.type ? <Alert data={alert} /> : <div />}</>;
}

export default Returning;
