import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { API_URL_VKS as API_URL, getObjFromLS } from "../../utils/helpers";

import Alert from "../../components/Alert";
import Textarea from "../../components/Textarea";
import Checkbox from "../../components/Checkbox";
import Msg from "../../components/Msg";
import Submit from "../../components/Submit";
import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

function Quest2({ cares }) {
  const [user, setUser] = useState({});
  const [errors, setErrors] = useState([]);
  const [names, setNames] = useState({});
  const [msg, setMsg] = useState({});
  const [alert, setAlert] = useState({
    type: "working",
    text: "",
  });

  const formElement = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    if (getObjFromLS("scholarship")) {
      const obj = getObjFromLS("scholarship");
      setUser({
        token: obj.token,
        id: obj.id,
        email: obj.email,
      });

      init(obj.token);
    } else {
      navigate("/enter");
    }
  }, []);

  const init = async (token) => {
    const url = new URL(`${API_URL}/admin/stepInit`);
    const params = { token, step: "quest6" };
    url.search = new URLSearchParams(params);

    try {
      const response = await fetch(url, {
        method: "GET",
        cache: "no-store",
      });
      const json = await response.json();
      if (json && json.resp === 1) {
        setNames((names) => ({ ...names, ...json }));

        setAlert({});
      } else {
        setAlert({
          type: "error",
          text: "An error occurred accessing your application.",
        });
      }
    } catch (error) {
      setAlert({
        type: "error",
        text: "An error occurred accessing your application.",
      });
    }
  };

  const handleUpdate = (name, value) => {
    if (name === "unknown") {
      const checked = !names.unknown;
      setNames((names) => ({
        ...names,
        [name]: checked,
      }));
    } else {
      setNames((names) => ({ ...names, [name]: value ? value : "" }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors([]);
    setMsg({
      type: "working",
      text: "",
    });

    const url = new URL(`${API_URL}/admin/quest6`);
    let formData = new FormData();
    Object.entries(names).forEach(([key, value]) =>
      formData.append(key, value)
    );
    formData.append("unknown", names.unknown ? "1" : "0");
    formData.append("token", user.token);

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });
      const json = await response.json();
      if (json && json.resp === 1) {
        navigate("/");
      } else {
        setErrors(json.fields);
        setMsg({
          type: "error",
          text: json.text,
        });
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      });
    }
  };

  return (
    <>
      {alert.type ? (
        <Alert data={alert} />
      ) : (
        <form
          method="post"
          action="/"
          onSubmit={(e) => handleSubmit(e)}
          ref={formElement}
        >
          <h2>Section #6 - College Plans</h2>

          <Link to="/">Back to Home</Link>

          <div>
            <Checkbox
              name="unknown"
              update={handleUpdate}
              data={[
                {
                  value: false,
                  label:
                    "Check if your college/university plans are unknown at this time",
                },
              ].map((item) => {
                return {
                  value: item.value,
                  label: item.label,
                  checked: names.unknown,
                };
              })}
              cares={cares}
            />
          </div>

          <div className={names.unknown ? "hidden" : ""}>
            <div>
              <Textarea
                label="If you know, please provide the colleges/universities you plan to apply to/attend and your planned major."
                req={true}
                name="college1"
                value={names.college1}
                update={handleUpdate}
                errors={errors}
              />
            </div>
          </div>

          <div className={names.unknown ? "hidden" : ""}>
            <div>
              <Textarea
                label="If you know, please advise your career goals after college/university."
                req={false}
                name="college2"
                value={names.college2}
                update={handleUpdate}
                errors={errors}
              />
            </div>
          </div>

          <div className="msg-submit">
            {msg.type && <Msg data={msg} />}

            <div
              className={msg.type === "working" ? "hidden" : "submit-container"}
            >
              <Submit
                name="Submit Section #6"
                icon={faChevronCircleRight}
                cares={cares}
              />
            </div>
          </div>
          <p>
            We will not share your email address with third-parties. For more
            information on how we use your information, see our{" "}
            <a
              href="https://img.com/privacypolicy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            .
          </p>
          <p className="spacer" />
        </form>
      )}
    </>
  );
}

export default Quest2;
