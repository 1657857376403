import { useState, useEffect } from "react";
import { API_URL_CC as API_URL, fmtCurrency } from "../../utils/helpers";
import Input from "../../components/Input";
import Radios from "../../components/Radios";
import Checkbox from "../../components/Checkbox";
import Msg from "../../components/Msg";
import Submit from "../../components/Submit";
import Alert from "../../components/Alert";
import Waiver from "./Waiver";
import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

function getLevels(type) {
  return type === "existing"
    ? [
        {
          value: "premiere",
          label: "$1700 - CC Premiere Membership",
        },

        {
          value: "classic",
          label: "$850 - CC Classic Membership",
        },
      ]
    : type === "new"
    ? [
        {
          value: "new",
          label: "$850 - CC New Membership",
        },
      ]
    : [
        {
          value: "twenties",
          label: "$500 - CC Twenties Membership",
        },
      ];
}

const Details2 = ({ id, status, type }) => {
  const [errors, setErrors] = useState([]);
  const [names, setNames] = useState({
    deposit: false,
    amount: 0,
    donation: 0,
  });
  const [contact, setContact] = useState({});
  const [total, setTotal] = useState(0);
  const [msg, setMsg] = useState({});
  const [alert, setAlert] = useState({
    type: "working",
    text: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    init(id);
  }, [id]);

  const init = async (id) => {
    const url = new URL(`${API_URL}/details2Init`);
    const params = { id };
    url.search = new URLSearchParams(params);

    try {
      const response = await fetch(url, {
        method: "GET",
        cache: "no-store",
      });
      const json = await response.json();
      if (json && json.resp === 1) {
        setContact({
          name: json.name,
          line1: json.line1,
          line2: json.line2,
          phone: json.phone,
          email: json.email,
        });
        setAlert({});
      } else {
        setAlert({
          type: "error",
          text: json.text,
        });
      }
    } catch (error) {
      setAlert({
        type: "error",
        text: "An error has occurred.",
      });
    }
  };

  const handleUpdate = (name, value) => {
    if (name === "fees") {
      const checked = !names.fees;
      let newAmt = names.amount + names.donation;
      if (checked) {
        newAmt = newAmt * 0.027 + newAmt;
      }
      setNames((names) => ({ ...names, [name]: checked }));
      setTotal(newAmt);
      /*
    } else if (names.level && name === "deposit") {
      const amount =
        names.level === "premiere" && !value
          ? 1600
          : names.level === "premiere" && value
          ? 800
          : names.level === "classic" && !value
          ? 850
          : 425;

      const newAmt = names.donation + amount;
      setNames((names) => ({ ...names, [name]: value, amount }));
      setTotal(newAmt);
    */
    } else if (name === "level") {
      const amount =
        value === "premiere" ? 1700 : value === "twenties" ? 500 : 850;
      let newAmt = names.donation + amount;
      if (names.fees) {
        newAmt = newAmt * 0.027 + newAmt;
      }
      setNames((names) => ({ ...names, [name]: value, amount }));
      setTotal(newAmt);
    } else if (name === "donation") {
      if (!isNaN(value.replace("$", ""))) {
        const donationAmt = value.replace("$", "");
        let newAmt = names.amount + Number(donationAmt);
        if (names.fees) {
          newAmt = newAmt * 0.027 + newAmt;
        }
        setNames((names) => ({
          ...names,
          [name]: value ? Number(donationAmt) : 0,
        }));
        setTotal(newAmt);
      }
    } else {
      setNames((names) => ({ ...names, [name]: value ? value : "" }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors([]);
    setMsg({
      type: "working",
      text: "",
    });

    const url = new URL(`${API_URL}/details2`);
    let data = new FormData();
    Object.entries(names).forEach(([key, value]) => {
      if (key === "deposit" || key === "fees") {
        data.append(key, value ? "1" : "0");
      } else {
        data.append(key, value);
      }
    });
    data.append("total", total || "0");
    data.append("id", id || "0");

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
      });
      const json = await response.json();
      if (json && json.resp === 1) {
        setMsg({});
        status(json.id, 2);
      } else {
        setErrors(json.fields);
        setMsg({
          type: "error",
          text: json.text,
        });
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      });
    }
  };

  return (
    <>
      {alert.type ? (
        <Alert data={alert} />
      ) : (
        <form method="post" action="/" onSubmit={(e) => handleSubmit(e)}>
          <div className="grid">
            <p>
              {contact.name}
              <br />
              {contact.line1}
              <br />
              {contact.line2}
            </p>
            <p>
              {contact.phone}
              <br />
              {contact.email}
            </p>
          </div>

          <p className="line" />

          <h3>Section 6 - Membership Details & Optional Donation</h3>

          <div>
            <Radios
              name="level"
              hdg="Select Membership Level"
              update={handleUpdate}
              data={getLevels(type).map((item) => {
                return {
                  value: item.value,
                  label: item.label,
                  checked: names.level === item.value ? true : false,
                };
              })}
            />
          </div>

          <p style={{ marginBottom: "0" }}>
            Benefits include: Honda Classic tickets including access to CouTOURe
            Club Chalet, parking and grounds. Amazing sponsored uniforms and
            access to great CC and Honda Classic parties.
          </p>

          <p className="line less-top-margin" />

          <p>
            Enter an amount (i.e. $100) below to make 100% tax deductible
            donation to Honda Classic Cares.
          </p>

          <div className="one">
            <div>
              <Input
                type="text"
                label="Donation to Honda Classic Cares"
                note=""
                req={false}
                name="donation"
                value={names.donation}
                update={handleUpdate}
                errors={errors}
              />
            </div>
          </div>

          <p className="line" />

          <p>Processing Fees</p>

          <div>
            <Checkbox
              name="fees"
              update={handleUpdate}
              data={[
                {
                  value: false,
                  label: "I would like to cover the 2.7% in processing fees",
                },
              ].map((item) => {
                return {
                  value: item.value,
                  label: item.label,
                  checked: names.fees,
                };
              })}
            />
          </div>

          {/*
            <p className="line" />

            <p>
              Choose yes to share your personal information with the PGA TOUR and
              Volunteer Appreciation Program sponsor so that you can receive news
              and product information from each and receive your custom and
              exclusive collapsible sport water bottle and carabiner clip - only
              available through this special offer!
            </p>

            <div className="grid start">
              <div>
                <Radios
                  name="gift"
                  hdg="Special Offer Opt In"
                  update={handleUpdate}
                  data={[
                    {
                      value: "Yes",
                      label: "Yes",
                    },
                    {
                      value: "No",
                      label: "No",
                    },
                  ].map((item) => {
                    return {
                      value: item.value,
                      label: item.label,
                      checked: names.gift === item.value ? true : false,
                    };
                  })}
                />

                <p>*Must be 18 years of age to participate</p>

                <p>
                  <a
                    href="https://pgatourvolunteer.dja.com/pages/rules.php"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View terms of this special offer
                  </a>
                </p>
              </div>

              <div>
                <img
                  src="https://thchcc.qnewmedia.com/k-assets/coutoure-club-gift.png"
                  alt="PGA TOUR Tumbler"
                />
              </div>
            </div>
          */}

          <p className="line" />

          <h3>
            Release and Waiver of All Liability and Assumption of Risk Agreement
          </h3>

          <Waiver />

          <p>
            I have read the release and agree to be bound by the terms set forth
            herein in consideration for performing any volunteering
            opportunities for The Classic in the Palm Beaches as a CouTOURe Club member.
          </p>

          <div className="one">
            <div>
              <Input
                type="text"
                label="Full Name"
                req={true}
                name="signed"
                value={names.signed}
                update={handleUpdate}
                errors={errors}
              />
            </div>
          </div>

          <p className="line" />

          <p>
            TOTAL AMOUNT TO CHARGE: <strong>{fmtCurrency(total)}</strong>
          </p>

          <div className="msg-submit">
            {msg.type && <Msg data={msg} />}

            <div
              className={msg.type === "working" ? "hidden" : "submit-container"}
            >
              <Submit name="Continue to Payment" icon={faChevronCircleRight} />
            </div>
          </div>

          <p className="spacer" />
        </form>
      )}
    </>
  );
};

export default Details2;
