import React from "react";
import "./styles.css";

function isFieldError(array, val) {
  return array.includes(val);
}

const Textarea = ({ label, req, name, value, update, errors }) => {
  return (
    <div
      className={
        name === "mission" || name === "text" ? "textarea larger" : "textarea"
      }
    >
      <label htmlFor={name} className={!label ? "hidden" : ""}>
        {`${label}`} {req ? <span className="error">*</span> : null}
      </label>
      <textarea
        name={name}
        id={name}
        value={value || ""}
        onChange={(e) => update(name, e.target.value)}
        autoComplete="off"
        className={isFieldError(errors, name) ? "error-border" : ""}
      />
    </div>
  );
};

export default Textarea;
