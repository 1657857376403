import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

import Home from "./Home";
import Existing from "./Existing";
import New from "./New";
import Twenties from "./Twenties";

function Redirect() {
	window.location =
		"https://events.r2it.com/volunteer/palmbeachclassic/2024/Coutoure.aspx";
}

function Match() {
	const [searchParams, setSearchParams] = useSearchParams();

	const type = searchParams.get("type") || "";

	return (
		<>
			{type === "existing" ? (
				<Existing />
			) : type === "new" ? (
				<Redirect />
			) : type === "twenties" ? (
				<Twenties />
			) : type === "junior" ? (
				<Redirect />
			) : (
				<Redirect />
			)}
		</>
	);
}

function CoutoureClub() {
	return (
		<Router basename="/coutoureclub">
			<Routes>
				<Route path="*" element={<Match />} />
			</Routes>
		</Router>
	);
}

export default CoutoureClub;
