import React from "react";
import "./styles.css";

export default ({ data, selected, click }) => {
  return (
    <button
      type="button"
      className={selected ? "button selected" : "button"}
      onClick={() => click("amount", data.value)}
    >
      <span>
        <span>{data.name}</span>
      </span>
    </button>
  );
};
