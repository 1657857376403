import { useState, useEffect, useRef } from "react";
import { API_URL_WLF } from "../../utils/helpers";
import Alert from "../../components/Alert";
import Input from "../../components/Input";
import Checkbox from "../../components/Checkbox";
import Button from "../../components/Button";
import Msg from "../../components/Msg";
import Submit from "../../components/Submit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleRight,
  faTrashAlt,
  faCirclePlus,
} from "@fortawesome/pro-light-svg-icons";

const Payment = ({ id, status }) => {
  const [names, setNames] = useState({
    myself: false,
  });
  const [details, setDetails] = useState({});
  const [attendees, setAttendees] = useState([]);
  const [alert, setAlert] = useState({
    type: "working",
    text: "",
  });
  const [errors, setErrors] = useState([]);
  const [msg, setMsg] = useState({});

  const formElement = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
    init(id);
  }, [id]);

  const init = async (id) => {
    const url = new URL(`${API_URL_WLF}/details2Init`);
    const params = { id };
    url.search = new URLSearchParams(params);

    try {
      const response = await fetch(url, {
        method: "GET",
        cache: "no-store",
      });
      const json = await response.json();
      if (json && json.resp === 1) {
        setDetails({
          fname: json.fname,
          lname: json.lname,
          email: json.email,
          seats: json.seats,
          description: json.description,
          total: json.total,
        });

        setAlert({});
      } else {
        setAlert({
          type: "error",
          text: json.text,
        });
      }
    } catch (error) {
      setAlert({
        type: "error",
        text: "An error has occurred.",
      });
    }
  };

  const handleUpdate = (name, value) => {
    if (name === "myself") {
      const checked = !names.myself;
      setNames((names) => ({ ...names, [name]: checked }));

      let fullName = `${details.fname} ${details.lname}`;
      let email = details.email;
      if (!checked) {
        fullName = "";
        email = "";
      }

      setNames((names) => ({
        ...names,
        name: fullName,
        email,
      }));
      formElement.current.name.value = fullName;
      formElement.current.email.value = email;
    } else {
      setNames((names) => ({ ...names, [name]: value ? value : "" }));
    }
  };

  const handleClick = () => {
    if (names.name && names.email && attendees.length < details.seats) {
      let newArray = attendees;
      newArray.push({
        name: names.name,
        email: names.email,
      });
      setNames((names) => ({ ...names, myself: false, name: "", email: "" }));
      formElement.current.name.value = "";
      formElement.current.email.value = "";
    } else {
      //alert("Name and email address required to add as attendee.")
    }
  };

  const handleRemove = (index) => {
    let newArray = attendees;
    newArray.splice(index, 1);
    setAttendees([...newArray]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors([]);
    setMsg({
      type: "working",
      text: "",
    });

    const url = new URL(`${API_URL_WLF}/details2`);
    let data = new FormData();
    Object.entries(names).forEach(([key, value]) => {
      if (key === "myself" || key === "name" || key === "email") {
      } else {
        data.append(key, value);
      }
    });
    data.append("seats", details.seats);
    data.append("attendees", JSON.stringify(attendees.slice(0, details.seats)));
    data.append("id", id || "0");

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
      });
      const json = await response.json();
      if (json && json.resp === 1) {
        setAlert({});
        status(json.id, 2);
      } else {
        setErrors(json.fields);
        setMsg({
          type: "error",
          text: json.text,
        });
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      });
    }
  };

  return (
    <>
      {alert.type ? (
        <Alert data={alert} />
      ) : (
        <>
          <form
            method="post"
            action="/"
            onSubmit={(e) => handleSubmit(e)}
            ref={formElement}
          >
            <p>
              Thank you for supporting the 2023 Women’s Leadership Forum
              presented by Jupiter Medical Center. Please indicate the
              attendee(s) below for the tickets (seats) you are purchasing.
            </p>

            <div className="receipt" style={{ marginBottom: "20px" }}>
              <table className="order">
                <thead>
                  <tr>
                    <th>Seats</th>
                    <th>Ticket Type</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{details.seats}</td>
                    <td>{details.description}</td>
                    <td>{details.total}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <p className="line" />

            <div style={{ padding: "0 25px" }}>
              <Checkbox
                name="myself"
                update={handleUpdate}
                data={[
                  {
                    value: false,
                    label: "Check to add yourself to a seat",
                  },
                ].map((item) => {
                  return {
                    value: item.value,
                    label: item.label,
                    checked: names.myself,
                  };
                })}
              />
            </div>

            <div className="two" style={{ padding: "0 25px" }}>
              <div>
                <Input
                  type="text"
                  label="Name"
                  req={true}
                  name="name"
                  value={names.name}
                  update={handleUpdate}
                  errors={errors}
                />
              </div>

              <div>
                <Input
                  type="text"
                  label="Email"
                  req={true}
                  name="email"
                  value={names.email}
                  update={handleUpdate}
                  errors={errors}
                />
              </div>
            </div>

            <div style={{ textAlign: "center", marginTop: "25px" }}>
              <Button
                name="Add Attendee"
                icon={faCirclePlus}
                click={handleClick}
                className="naked"
              />
            </div>

            <p className="line" />

            <div className="receipt" style={{ marginBottom: "20px" }}>
              <table className="order">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th style={{ width: "15%" }}>Remove</th>
                  </tr>
                </thead>
                <tbody>
                  {[...Array(details.seats).keys()].map((index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {attendees[index] ? attendees[index].name : "-"}
                        </td>
                        <td>
                          {attendees[index] ? attendees[index].email : "-"}
                        </td>
                        <td>
                          {" "}
                          {attendees[index] ? (
                            <button
                              type="button"
                              onClick={() => handleRemove(index)}
                              style={{
                                border: "none",
                                background: "none",
                                color: "var(--error)",
                                cursor: "pointer",
                              }}
                            >
                              <FontAwesomeIcon icon={faTrashAlt} size="lg" />
                            </button>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="msg-submit">
              {msg.type && <Msg data={msg} />}

              <div
                className={
                  msg.type === "working" ? "hidden" : "submit-container"
                }
              >
                <Submit
                  name="Continue to Payment"
                  icon={faChevronCircleRight}
                />
              </div>
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default Payment;
