import { useState, useEffect, useRef } from "react";
import { API_URL_WLF, API_URL, fmtCurrency } from "../../utils/helpers";
import Radios from "../../components/Radios";
import Alert from "../../components/Alert";
import Msg from "../../components/Msg";
import Submit from "../../components/Submit";
import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

const Payment = ({ id, status }) => {
  const [names, setNames] = useState({
    amount: 0,
    method: "card",
  });
  const [contact, setContact] = useState({});
  const [total, setTotal] = useState(0);
  const [alert, setAlert] = useState({
    type: "working",
    text: "",
  });
  const [errors, setErrors] = useState([]);
  const [msg, setMsg] = useState({});

  const iframeRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    init(id);
  }, [id]);

  useEffect(() => {
    const handler = (event) => {
      if (event && event.data && JSON.parse(event.data)) {
        const obj = JSON.parse(event.data);
        if (obj.status_id === 101) {
          setAlert({
            type: "working",
            text: "",
          });
          handleZeamster(obj);
        }
      }
    };

    window.addEventListener("message", handler);
    return () => window.removeEventListener("message", handler);
    // eslint-disable-next-line
  }, []);

  const init = async (id) => {
    const url = new URL(`${API_URL_WLF}/paymentInit`);
    const params = { id };
    url.search = new URLSearchParams(params);

    try {
      const response = await fetch(url, {
        method: "GET",
        cache: "no-store",
      });
      const json = await response.json();
      if (json && json.resp === 1) {
        setContact({
          name: json.name,
          line1: json.line1,
          line2: json.line2,
          phone: json.phone,
          email: json.email,
          items: json.items,
          amount: json.amount,
          url: json.url,
        });
        handleUpdate("amount", json.amount);
        setAlert({});
      } else {
        setAlert({
          type: "error",
          text: json.text,
        });
      }
    } catch (error) {
      setAlert({
        type: "error",
        text: "An error has occurred.",
      });
    }
  };

  const handleUpdate = (name, value) => {
    if (name === "amount") {
      const newAmt = value;
      setNames((names) => ({ ...names, [name]: value ? value : 0 }));
      setTotal(newAmt);
    } else {
      setNames((names) => ({ ...names, [name]: value ? value : "" }));
    }
  };

  const handleSubmit = async (e) => {};

  /* only needed for check payment
    const handleSubmit = async (e) => {
      e.preventDefault();
      setErrors([]);
      setMsg({
        type: "working",
        text: "",
      });

      const url = new URL(`${API_URL_WLF}/payment`);
      let data = new FormData();
      Object.entries(names).forEach(([key, value]) => data.append(key, value));
      data.append("id", id || "0");

      try {
        const response = await fetch(url, {
          method: "POST",
          body: data,
        });
        const json = await response.json();
        if (json && json.resp === 1) {
          setAlert({});
          status(id, 1);
        } else {
          setErrors(json.fields);
          setMsg({
            type: "error",
            text: json.text,
          });
        }
      } catch (error) {
        setMsg({
          type: "error",
          text: "An error has occurred.",
        });
      }
    };
  */

  const handleZeamster = async (obj) => {
    const url = new URL(`${API_URL}/zeamsterpayment`);
    let data = new FormData();
    data.append("id", id || "0");
    data.append("zmster", JSON.stringify(obj) || "");

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
      });
      const json = await response.json();
      if (json && json.resp === 1) {
        setAlert({});
        status(id, 1);
      } else {
        setAlert({
          type: "error",
          text: json.text,
        });
      }
    } catch (error) {
      setAlert({
        type: "error",
        text: "An error has occurred.",
      });
    }
  };

  return (
    <>
      {alert.type ? (
        <Alert data={alert} />
      ) : (
        <>
          <form method="post" action="/" onSubmit={(e) => handleSubmit(e)}>
            <p>
              Thank you for adding your attendee(s), please proceed with payment
              below in order to complete your ticket purchase.
            </p>

            <div className="grid">
              <p>
                {contact.name}
                <br />
                {contact.line1}
                <br />
                {contact.line2}
              </p>
              <p>
                {contact.phone}
                <br />
                {contact.email}
              </p>
            </div>

            <div className="receipt" style={{ marginTop: "20px" }}>
              <table className="order">
                <thead>
                  <tr>
                    <th>Qty</th>
                    <th className="left">Item</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {contact.items.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className="center">
                          <span>{item.qty}</span>
                        </td>
                        <td>
                          <div>
                            <span>{item.item}</span>
                            <small>{item.subitem}</small>
                          </div>
                        </td>
                        <td className="right">{item.price}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div>
              <Radios
                name="amount"
                hdg="Payment Amount"
                update={handleUpdate}
                data={[
                  {
                    value: contact.amount,
                    label: `${fmtCurrency(contact.amount)}`,
                  },
                ].map((item) => {
                  return {
                    value: item.value,
                    label: item.label,
                    checked: names.amount === item.value ? true : false,
                  };
                })}
              />
            </div>

            {/* only card option
            <div>
              <Radios
                name="method"
                hdg="Payment Method"
                update={handleUpdate}
                data={[
                  {
                    value: "check",
                    label: "Mail a Check",
                  },
                  {
                    value: "card",
                    label: "Credit / Debit Card",
                  },
                ].map((item) => {
                  return {
                    value: item.value,
                    label: item.label,
                    checked: names.method === item.value ? true : false,
                  };
                })}
              />
            </div>*/}

            {names.method === "check" && (
              <p>
                To complete your Ambassadors Club registration please mail a
                check made out to The Classic in the Palm Beaches and mail to:
              </p>
            )}

            {names.method === "check" && (
              <p>
                The Classic in the Palm Beaches
                <br />
                Attn: Ambassadors Club
                <br />
                3300 PGA Blvd Suite 800
                <br />
                Palm Beach Gardens, FL 33410
              </p>
            )}

            {names.method === "check" && (
              <div className="msg-submit">
                {msg.type && <Msg data={msg} />}

                <div
                  className={
                    msg.type === "working" ? "hidden" : "submit-container"
                  }
                >
                  <Submit
                    name="Submit Registration"
                    icon={faChevronCircleRight}
                  />
                </div>
              </div>
            )}
          </form>

          <iframe
            ref={iframeRef}
            src={contact.url}
            title="Payment Form"
            className={names.method === "card" ? "" : "hidden"}
          />
        </>
      )}
    </>
  );
};

export default Payment;
