import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  API_URL_VKS as API_URL,
  getObjFromLS,
  setObjToLS,
} from "../../../utils/helpers";

import Alert from "../../../components/Alert";
import LinkBtn from "../../../components/LinkBtn";
import Button from "../../../components/Button";

import Tr from "./Tr";

import { faSignOut } from "@fortawesome/pro-light-svg-icons";

function Home({ cares }) {
  const [user, setUser] = useState({});
  const [sections, setSections] = useState([]);
  const [ready2submit, setReady2submit] = useState(false);
  const [alert, setAlert] = useState({
    type: "working",
    text: "",
  });

  const code = new URLSearchParams(useLocation().search).get("code") || "";
  const navigate = useNavigate();

  useEffect(() => {
    if (code) {
      navigate(`/returning/${code}`);
    } else {
      window.scrollTo(0, 0);
      if (getObjFromLS("scholarship")) {
        const obj = getObjFromLS("scholarship");
        setUser({
          token: obj.token,
          id: obj.id,
          email: obj.email,
        });
        init(obj.token);
      } else {
        navigate("/enter");
      }
    }
  }, []);

  const init = async (token) => {
    const url = new URL(`${API_URL}/admin/homeInit`);
    const params = { token };
    url.search = new URLSearchParams(params);

    try {
      const response = await fetch(url, {
        method: "GET",
        cache: "no-store",
      });
      const json = await response.json();
      if (json && json.resp === 1) {
        setSections(json.sections);
        setReady2submit(json.ready2submit);
        setAlert({});
      } else if (json && json.resp === 2) {
        setAlert({
          type: "success",
          text: json.text,
        });
        setObjToLS("scholarship", null);
      } else {
        setAlert({
          type: "error",
          text: json.text,
        });
      }
    } catch (error) {
      setAlert({
        type: "error",
        text: "An error occurred accessing your application.",
      });
    }
  };

  const handleSignout = (int) => {
    setObjToLS("scholarship", null);
    navigate("/enter");
  };

  return (
    <>
      {alert.type ? (
        <Alert data={alert} />
      ) : (
        <div className="scholarship">
          <h2 style={{ textAlign: "center", marginBottom: "25px" }}>
            Victoria Kingdom Scholarship Application
          </h2>

          <div className="signout-container">
            {ready2submit ? (
              <div>
                <p>
                  Thank you for completing all sections of the application,
                  please click the button below to submit your application to
                  Honda Classic Cares.
                </p>
                <p>You are signed is as {user.email}.</p>
              </div>
            ) : (
              <div>
                <p>
                  Please complete all sections of the application below and
                  submit to Honda Classic Cares for consideration once you are
                  ready.
                </p>

                <p>
                  Note that you can return later to complete your application by
                  signing in with your email address ({user.email}).
                </p>
              </div>
            )}

            <Button
              name="Sign Out"
              icon={faSignOut}
              click={handleSignout}
              cares={true}
            />
          </div>

          <table className="status" style={{ marginTop: "0" }}>
            <thead>
              <tr>
                <th>Status</th>
                <th className="left">Step / Section Description</th>
                <th>Last Updated</th>
              </tr>
            </thead>

            <tbody>
              {sections.map((section) => {
                return <Tr data={section} key={section.section} />;
              })}
            </tbody>
          </table>

          {ready2submit && (
            <div style={{ marginTop: "25px" }}>
              <LinkBtn
                data={{
                  to: "/submit",
                  name: "Submit completed application to HCC",
                }}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default Home;
