import { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";

import { API_URL_BFC as API_URL } from "../../utils/helpers";

import Alert from "../../components/Alert";

const BFCCharities = ({ cares, closed }) => {
	const [details, setDetails] = useState({
		items: [],
	});
	const [alert, setAlert] = useState({
		type: "working",
		text: "",
	});

	const initRef = useRef(false);

	useEffect(() => {
		if (initRef.current) return;
		initRef.current = true;

		const urlParams = new URLSearchParams(window.location.search);
		if (urlParams.has("token")) {
			window.sessionStorage.setItem("BFCAdmin", urlParams.get("token"));
		}
		init();
	});

	const init = async () => {
		const url = new URL(`${API_URL}/`);
		const params = {
			f: `cdn`,
			p2f: `/k-bfc/api/?f=charitiesInit`,
			x: uuidv4(),
		};
		url.search = new URLSearchParams(params);

		try {
			const response = await fetch(url, {
				method: "GET",
				cache: "no-store",
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setDetails({
					updated: json.updated,
					items: json.items,
				});
				setAlert({});
			}
		} catch (error) {
			setAlert({
				type: "error",
				text: "An error has occurredddd.",
			});
		}
	};

	return (
		<>
			{alert.type ? (
				<Alert data={alert} />
			) : (
				<>
					<p className="line" />

					<h3>Participating Charities</h3>

					<p>
						Donate to benefit your charity of choice by clicking on the charity
						name below - last day to pledge is Wednesday, February 28th at 9:00
						pm.
					</p>

					<ul className="charities-grid">
						{details.items.map((item) => {
							return (
								<li key={item.id}>
									<a href={item.url}>{item.name}</a>
								</li>
							);
						})}
					</ul>
				</>
			)}
		</>
	);
};

export default BFCCharities;
