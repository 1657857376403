import { Link } from "react-router-dom";
import "./styles.css";

export default ({ data }) => {
  return (
    <Link to={data.to} className="btn-link">
      <span>{data.name}</span>
    </Link>
  );
};
