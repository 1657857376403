import { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";

import { API_URL_BFC as API_URL } from "../../utils/helpers";

import Alert from "../../components/Alert";

const BFCStandings = ({ cares }) => {
	const [details, setDetails] = useState({
		items: [],
	});
	const [alert, setAlert] = useState({
		type: "working",
		text: "",
	});

	const initRef = useRef(false);

	useEffect(() => {
		if (initRef.current) return;
		initRef.current = true;
		init();
	});
	const init = async () => {
		const url = new URL(`${API_URL}/`);
		const params = {
			f: `cdn`,
			p2f: `/k-bfc/api/?f=standingsInit`,
			x: uuidv4(),
		};
		url.search = new URLSearchParams(params);

		try {
			const response = await fetch(url, {
				method: "GET",
				cache: "no-store",
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setDetails({
					total: json.total,
					updated: json.updated,
					items: json.items,
				});
				setAlert({});
			}
		} catch (error) {
			setAlert({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	return (
		<>
			{alert.type ? (
				<Alert data={alert} />
			) : (
				<>
					<h2 className="standings-title">
						<strong>{details.total}</strong> in total donations received through
						the 2024 Birdies for Children program.
					</h2>
					<table className="standings">
						<thead>
							<tr>
								<th className="pos">Place</th>
								<th className="left">Charity</th>
								<th className="total">Total</th>
							</tr>
						</thead>

						<tbody>
							{details.items.map((item, index) => {
								return (
									<tr key={index}>
										<td className="pos">{item.pos}</td>
										<td>{item.charity}</td>
										<td className="total">{item.amt}</td>
									</tr>
								);
							})}
						</tbody>
						<tfoot>
							<tr>
								<th colSpan="2" className="right">
									Total Donations Collected (updated {details.updated})
								</th>
								<th className="right">{details.total}</th>
							</tr>
						</tfoot>
					</table>
				</>
			)}
		</>
	);
};

export default BFCStandings;
