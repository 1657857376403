import { useState, useEffect, useRef } from "react";
import { API_URL_CC, API_URL } from "../../utils/helpers";
import Alert from "../../components/Alert";
import Radios from "../../components/Radios";
import Msg from "../../components/Msg";
import Submit from "../../components/Submit";
import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

function getMethods(methods) {
  return methods === "card"
    ? [
        {
          value: "card",
          label: "Credit / Debit Card",
        },
      ]
    : [
        {
          value: "card",
          label: "Credit / Debit Card",
        },
        {
          value: "check",
          label: "Mail a Check (to be received by 10/1/22)",
        },
      ];
}

const Payment = ({ id, status, methods = "both" }) => {
  const [order, setOrder] = useState({});
  const [alert, setAlert] = useState({
    type: "working",
    text: "",
  });
  const [errors, setErrors] = useState([]);
  const [names, setNames] = useState({});
  const [msg, setMsg] = useState({});

  const iframeRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    init();
  }, []);

  useEffect(() => {
    const handler = (event) => {
      if (event && event.data && JSON.parse(event.data)) {
        const obj = JSON.parse(event.data);
        if (obj.status_id === 101) {
          setAlert({
            type: "working",
            text: "",
          });
          handleZeamster(obj);
        }
      }
    };

    window.addEventListener("message", handler);
    return () => window.removeEventListener("message", handler);
    // eslint-disable-next-line
  }, []);

  const init = async () => {
    const url = new URL(`${API_URL_CC}/paymentInit`);
    const params = { id };
    url.search = new URLSearchParams(params);

    try {
      const response = await fetch(url, {
        method: "GET",
        cache: "no-store",
      });
      const json = await response.json();
      if (json && json.resp === 1) {
        setOrder({
          items: json.items,
          totals: json.totals,
          text: json.text,
          url: json.url,
        });
        setAlert({});
      } else {
        setAlert({
          type: "error",
          text: json.text,
        });
      }
    } catch (error) {
      setAlert({
        type: "error",
        text: "An error has occurred.",
      });
    }
  };

  const handleUpdate = (name, value) => {
    setNames((names) => ({ ...names, [name]: value ? value : "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors([]);
    setMsg({
      type: "working",
      text: "",
    });

    const url = new URL(`${API_URL_CC}/payment`);
    let data = new FormData();
    Object.entries(names).forEach(([key, value]) => data.append(key, value));
    data.append("id", id || "0");

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
      });
      const json = await response.json();
      if (json && json.resp === 1) {
        setAlert({});
        status(id, 1);
      } else {
        setErrors(json.fields);
        setMsg({
          type: "error",
          text: json.text,
        });
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      });
    }
  };

  const handleZeamster = async (obj) => {
    const url = new URL(`${API_URL}/zeamsterpayment`);
    let data = new FormData();
    data.append("id", id || "0");
    data.append("zmster", JSON.stringify(obj) || "");

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
      });
      const json = await response.json();
      if (json && json.resp === 1) {
        setAlert({});
        status(id, 1);
      } else {
        setAlert({
          type: "error",
          text: json.text,
        });
      }
    } catch (error) {
      setAlert({
        type: "error",
        text: "An error has occurred.",
      });
    }
  };

  return (
    <>
      {alert.type ? (
        <Alert data={alert} />
      ) : (
        <div className="receipt">
          <p>{order.text}</p>

          <table className="order">
            <thead>
              <tr>
                <th>Qty</th>
                <th className="left">Item</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {order.items.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="center">
                      <span>{item.qty}</span>
                    </td>
                    <td>
                      <div>
                        <span>{item.item}</span>
                        <small>{item.subitem}</small>
                      </div>
                    </td>
                    <td className="right">{item.price}</td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="2" className="right">
                  TOTAL
                </td>
                <td className="right">{order.totals.total}</td>
              </tr>
            </tfoot>
          </table>

          <h3>Section 7 - Payment</h3>

          <form method="post" action="/" onSubmit={(e) => handleSubmit(e)}>
            <div>
              <Radios
                name="method"
                hdg="Payment Method"
                update={handleUpdate}
                data={getMethods(methods).map((item) => {
                  return {
                    value: item.value,
                    label: item.label,
                    checked: names.method === item.value ? true : false,
                  };
                })}
              />
            </div>

            {names.method === "check" && (
              <p>
                To complete your CouTOURe Club registration please mail a check
                made out to The Classic in the Palm Beaches and mail to:
              </p>
            )}

            {names.method === "check" && (
              <p>
                The Classic in the Palm Beaches
                <br />
                Attn: CouTOURe Club
                <br />
                3300 PGA Blvd Suite 800
                <br />
                Palm Beach Gardens, FL 33410
              </p>
            )}

            {names.method === "check" && (
              <div className="msg-submit">
                {msg.type && <Msg data={msg} />}

                <div
                  className={
                    msg.type === "working" ? "hidden" : "submit-container"
                  }
                >
                  <Submit
                    name="Submit Registration"
                    icon={faChevronCircleRight}
                  />
                </div>
              </div>
            )}
          </form>

          <iframe
            ref={iframeRef}
            src={order.url}
            title="Payment Form"
            className={names.method === "card" ? "" : "hidden"}
          />

          <p className="spacer" />
        </div>
      )}
    </>
  );
};

export default Payment;
