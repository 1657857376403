import { useState, useEffect } from "react";
import { API_URL_JRCC, fmtCurrency } from "../../utils/helpers";
import Alert from "../../components/Alert";
import Input from "../../components/Input";
import Radios from "../../components/Radios";
import Checkbox from "../../components/Checkbox";
import Msg from "../../components/Msg";
import Submit from "../../components/Submit";
import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

const Payment = ({ id, status }) => {
  const [names, setNames] = useState({
    amount: 0,
    donation: 0,
  });
  const [contact, setContact] = useState({});
  const [total, setTotal] = useState(0);
  const [errors, setErrors] = useState([]);
  const [msg, setMsg] = useState({});
  const [alert, setAlert] = useState({
    type: "working",
    text: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    init(id);
  }, [id]);

  const init = async (id) => {
    const url = new URL(`${API_URL_JRCC}/details2Init`);
    const params = { id };
    url.search = new URLSearchParams(params);

    try {
      const response = await fetch(url, {
        method: "GET",
        cache: "no-store",
      });
      const json = await response.json();
      if (json && json.resp === 1) {
        setContact({
          name: json.name,
          line1: json.line1,
          line2: json.line2,
          phone: json.phone,
          email: json.email,
        });
        setAlert({});
      } else {
        setAlert({
          type: "error",
          text: json.text,
        });
      }
    } catch (error) {
      setAlert({
        type: "error",
        text: "An error has occurred.",
      });
    }
  };

  const handleUpdate = (name, value) => {
    if (name === "fees") {
      const checked = !names.fees;
      let newAmt = names.amount + names.donation;
      if (checked) {
        newAmt = newAmt * 0.027 + newAmt;
      }
      setNames((names) => ({ ...names, [name]: checked }));
      setTotal(newAmt);
    } else if (name === "amount") {
      const newAmt = names.donation + value;
      setNames((names) => ({ ...names, [name]: value ? value : 0 }));
      setTotal(newAmt);
    } else if (name === "donation") {
      if (!isNaN(value.replace("$", ""))) {
        const donationAmt = value.replace("$", "");
        let newAmt = names.amount + Number(donationAmt);
        if (names.fees) {
          newAmt = newAmt * 0.027 + newAmt;
        }
        setNames((names) => ({
          ...names,
          [name]: value ? Number(donationAmt) : 0,
        }));
        setTotal(newAmt);
      }
    } else {
      setNames((names) => ({ ...names, [name]: value ? value : "" }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors([]);
    setMsg({
      type: "working",
      text: "",
    });

    const url = new URL(`${API_URL_JRCC}/details2`);
    let data = new FormData();
    Object.entries(names).forEach(([key, value]) => {
      if (key === "fees") {
        data.append(key, value ? "1" : "0");
      } else {
        data.append(key, value);
      }
    });
    data.append("total", total || "0");
    data.append("id", id || "0");

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
      });
      const json = await response.json();
      if (json && json.resp === 1) {
        setAlert({});
        status(json.id, 2);
      } else {
        setErrors(json.fields);
        setMsg({
          type: "error",
          text: json.text,
        });
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      });
    }
  };

  return (
    <>
      {alert.type ? (
        <Alert data={alert} />
      ) : (
        <>
          <form method="post" action="/" onSubmit={(e) => handleSubmit(e)}>
            <p>
              Thank you for filling out the club membership registration form.
              Please proceed with payment below in order to complete the
              registration process. We will follow up with confirmation once we
              receive your registration and payment.
            </p>

            <div className="grid">
              <p>
                {contact.name}
                <br />
                {contact.line1}
                <br />
                {contact.line2}
              </p>
              <p>
                {contact.phone}
                <br />
                {contact.email}
              </p>
            </div>

            <p className="line" />

            <div>
              <Radios
                name="amount"
                hdg="Dues Payment Amount"
                update={handleUpdate}
                data={[
                  {
                    value: 200,
                    label: "$200 - Junior CouTOURe Club",
                  },
                ].map((item) => {
                  return {
                    value: item.value,
                    label: item.label,
                    checked: names.amount === item.value ? true : false,
                  };
                })}
              />
            </div>

            <p className="line less-top-margin" />

            <p>
              Enter an amount (i.e. $100) below to make 100% tax deductible
              donation to Honda Classic Cares and be credited to your membership
              credit requirement.
            </p>

            <div className="one">
              <div>
                <Input
                  type="text"
                  label="Donation to Honda Classic Cares"
                  note=""
                  req={false}
                  name="donation"
                  value={names.donation}
                  update={handleUpdate}
                  errors={errors}
                />
              </div>
            </div>

            <p className="line" />

            <p>Processing Fees</p>

            <div>
              <Checkbox
                name="fees"
                update={handleUpdate}
                data={[
                  {
                    value: false,
                    label: "I would like to cover the 2.7% in processing fees",
                  },
                ].map((item) => {
                  return {
                    value: item.value,
                    label: item.label,
                    checked: names.fees,
                  };
                })}
              />
            </div>

            <p className="line" />

            <h3>
              Release and Waiver of All Liability and Assumption of Risk
              Agreement
            </h3>

            <div className="waiver">
              <p>
                In connection with my activities and/or the activities of my
                minor child, I acknowledge that the provision of my time and
                services, and/or the time or services of my minor child, are as
                a volunteer at the 2023 Honda Classic Golf Tournament (the
                “Tournament”) being held at PGA National Resort and Spa in Palm
                Beach Gardens, Florida (the “Club”) without the expectation of
                payment and I understand that neither I nor my minor child will
                be compensated in connection with the Tournament. I acknowledge
                that neither I nor my minor child is or will be an employee of
                the Club, The Classic in the Palm Beaches, Children’s Healthcare Charity, Inc.
                (“CHC”), Nicklaus Children’s Health Care Foundation, the PGA
                TOUR, IMG Worldwide, LLC (“IMG”), WME, Endeavor, any sponsor of
                the Tournament, or any of their respective officers, partners,
                directors, members, employees, representatives, parent
                companies, affiliates, subsidiaries, insurers, successors and
                assigns, as well as any other volunteer (the “Tournament
                Parties”). I agree to comply, and cause my minor child to comply
                (to the extent applicable), with all Tournament and Club rules.
                In connection with my and/or my minor child’s activities as a
                volunteer at the Club for the Tournament, I agree and understand
                that the presence of me and/or my minor child at the Tournament
                and any volunteer work or services performed by me and/or my
                minor child for the Tournament Parties may expose me and/or my
                minor child to both known and unanticipated risks of harm or
                injury. In consideration of and as a prerequisite to the
                participation of me and/or my minor child as a volunteer, I, for
                myself and/or my minor child (to the extent applicable),
                acknowledge that such risks exist, hereby assume all such risks,
                and release and discharge the Tournament Parties and each of
                them from any and all claims for liability for personal injury
                (including death) and property damage that I and/or my minor
                child may suffer while performing such volunteer work or
                service, whether or not on the premises of the Club, including,
                without limitation, any claim arising out of any condition of
                the premises owned by any of the Tournament Parties, or the
                conduct of any person in connection with, supervision of or
                conduct of the Tournament or any practice round or activity
                connected or related to the Tournament. I further understand
                that the Tournament Parties may not maintain insurance which
                will cover me and/or my minor child for personal injury,
                property damage or medical expenses, and I accept full
                responsibility for the costs of treatment for any injury or
                damages suffered by me and/or my minor child while participating
                as a volunteer in connection with the Tournament. I further
                agree that in case any action being brought for or on behalf of
                myself and/or my minor child on account of any injury or damage
                sustained by me and/or my minor child while volunteering in
                connection with the Tournament, I will be personally responsible
                to, and agree to repay to, any of the Tournament Parties and
                hold each of them harmless against any amounts recovered in
                connection with any such action and all related costs incurred.
              </p>
            </div>

            <p>
              I have read the release and agree to be bound by the terms set
              forth herein in consideration for performing any volunteering
              opportunities for The Classic in the Palm Beaches as a Junior CouTOURe Club
              member.
            </p>

            <div className="one">
              <div>
                <Input
                  type="text"
                  label="Full Name"
                  req={true}
                  name="signed"
                  value={names.signed}
                  update={handleUpdate}
                  errors={errors}
                />
              </div>
            </div>

            <p className="line" />

            <p>
              TOTAL AMOUNT TO CHARGE: <strong>{fmtCurrency(total)}</strong>
            </p>

            <div className="msg-submit">
              {msg.type && <Msg data={msg} />}

              <div
                className={
                  msg.type === "working" ? "hidden" : "submit-container"
                }
              >
                <Submit
                  name="Continue to Payment"
                  icon={faChevronCircleRight}
                />
              </div>
            </div>

            <p className="spacer" />
          </form>
        </>
      )}
    </>
  );
};

export default Payment;
