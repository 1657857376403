import React, { useState } from "react";

import { API_URL_CONTACT as API_URL } from "../../utils/helpers";

import Input from "../../components/Input";
import Msg from "../../components/Msg";
import Submit from "../../components/Submit";
import Alert from "../../components/Alert";

import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

const Hospitality = ({ subject }) => {
	const [errors, setErrors] = useState([]);
	const [names, setNames] = useState({
		subject,
	});
	const [msg, setMsg] = useState({});
	const [alert, setAlert] = useState({});

	const handleUpdate = (name, value) => {
		if (name === "optin") {
			setNames((names) => ({ ...names, optin: !names.optin }));
		} else {
			setNames((names) => ({ ...names, [name]: value ? value : "" }));
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setErrors([]);
		setMsg({
			type: "working",
			text: "",
		});

		const url = new URL(`${API_URL}/?f=hospitality`);

		let data = new FormData();
		data.append("subject", names.subject || "");
		data.append("fname", names.fname || "");
		data.append("lname", names.lname || "");
		data.append("email", names.email || "");
		data.append("phone", names.phone || "");
		data.append("company", names.company || "");
		data.append("notes", names.notes || "");

		try {
			const response = await fetch(url, {
				method: "POST",
				body: data,
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setMsg({});
				setAlert({
					type: "success",
					text: json.text,
				});
			} else {
				setErrors(json.fields);
				setMsg({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setMsg({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	return (
		<>
			{alert.type ? (
				<Alert data={alert} />
			) : (
				<form method="post" action="/" onSubmit={(e) => handleSubmit(e)}>
					<h2>Need more information?</h2>

					{names.subject ? (
						<p style={{ textAlign: "center" }}>
							For more information regarding {names.subject}, please fill out
							the form below and a representative will be in contact with you.
						</p>
					) : (
						<p style={{ textAlign: "center" }}>
							For more information regarding this or other sponsorship
							opportunities please fill out the form below and a representative
							will be in contact with you.
						</p>
					)}

					<div className="three">
						<div>
							<Input
								type="text"
								label="First Name"
								req={true}
								name="fname"
								value={names.fname}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
						<div>
							<Input
								type="text"
								label="Last Name"
								req={true}
								name="lname"
								value={names.lname}
								update={handleUpdate}
								errors={errors}
							/>
						</div>

						<div>
							<Input
								type="text"
								label="Email Address"
								req={true}
								name="email"
								value={names.email}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
					</div>
					<div className="three">
						<div>
							<Input
								type="text"
								label="Phone"
								req={true}
								name="phone"
								value={names.phone}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
						<div>
							<Input
								type="text"
								label="Company / Organization"
								req={false}
								name="company"
								value={names.company}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
						<div>
							<Input
								type="text"
								label="Notes / Questions?"
								req={false}
								name="notes"
								value={names.notes}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
					</div>

					<div className="msg-submit">
						{msg.type && <Msg data={msg} />}

						<div
							className={msg.type === "working" ? "hidden" : "submit-container"}
						>
							<Submit name="Submit Info Request" icon={faChevronCircleRight} />
						</div>
					</div>

					<p className="center">
						We will not share your email address with third-parties. For more
						information on how we use your information, see our{" "}
						<a href="https://img.com/privacypolicy/">Privacy Policy</a>.
					</p>
				</form>
			)}
		</>
	);
};

export default Hospitality;
