import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { API_URL_VKS as API_URL, getObjFromLS } from "../../utils/helpers";

import Alert from "../../components/Alert";
import Input from "../../components/Input";
import Msg from "../../components/Msg";
import Submit from "../../components/Submit";
import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

function SubmitPage({ cares }) {
  const [user, setUser] = useState({});
  const [errors, setErrors] = useState([]);
  const [names, setNames] = useState({});
  const [msg, setMsg] = useState({});
  const [alert, setAlert] = useState({
    type: "working",
    text: "",
  });

  const formElement = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    if (getObjFromLS("scholarship")) {
      const obj = getObjFromLS("scholarship");
      setUser({
        token: obj.token,
        id: obj.id,
        email: obj.email,
      });

      init(obj.token);
    } else {
      navigate("/enter");
    }
  }, []);

  const init = async (token) => {
    const url = new URL(`${API_URL}/admin/homeInit`);
    const params = { token };
    url.search = new URLSearchParams(params);

    try {
      const response = await fetch(url, {
        method: "GET",
        cache: "no-store",
      });
      const json = await response.json();
      if (json && json.resp === 1) {
        if (json.ready2submit) {
          handleUpdate("applicant", json.applicant);
          setAlert({});
        } else {
          navigate("/");
        }
      } else if (json && json.resp === 2) {
        navigate("/");
      } else {
        setAlert({
          type: "error",
          text: json.text,
        });
      }
    } catch (error) {
      setAlert({
        type: "error",
        text: "An error occurred accessing your application.",
      });
    }
  };

  const handleUpdate = (name, value) => {
    setNames((names) => ({ ...names, [name]: value ? value : "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors([]);
    setMsg({
      type: "working",
      text: "",
    });

    const url = new URL(`${API_URL}/admin/submit`);
    let formData = new FormData();
    Object.entries(names).forEach(([key, value]) =>
      formData.append(key, value)
    );
    formData.append("token", user.token);

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });
      const json = await response.json();
      if (json && json.resp === 1) {
        navigate("/");
      } else {
        setErrors(json.fields);
        setMsg({
          type: "error",
          text: json.text,
        });
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      });
    }
  };

  return (
    <>
      {alert.type ? (
        <Alert data={alert} />
      ) : (
        <form
          method="post"
          action="/"
          onSubmit={(e) => handleSubmit(e)}
          ref={formElement}
        >
          <h2>Submit Application to Honda Classic Cares</h2>

          <Link to="/">Back to Home</Link>

          <h3>Media / Photo Consent</h3>

          <p>
            Please read and sign below to acknowledge the acceptance of photo
            and press release.
          </p>

          <div
            className="waiver"
            style={{ height: "auto", maxHeight: "175px" }}
          >
            <p>
              I, (name of Parent/Guardian entered below) of the participant (
              {names.applicant}) hereby consent that any photographs, motion
              pictures, any and all social media, or videotape in which my child
              appears and any audio recordings made of their voice, may be used
              by the Honda Classic, its assigns or successors, in whatever way
              they desire, including television and electric media. I also
              consent that such photographs, film, recording, social media and
              the plates and/or tapes from which they are made shall be the
              property of the Honda Classic and that the Honda Classic shall
              have the rights to sell, duplicate, reproduce and make other uses
              of such photographs, film, recording, social media and plates
              and/or tapes as they desire free and clear of any claim whatsoever
              on my part.
            </p>
          </div>

          <div className="one">
            <div>
              <Input
                type="text"
                label="Name of Parent/Guardian"
                req={true}
                name="signed1"
                value={names.signed1}
                update={handleUpdate}
                errors={errors}
              />
            </div>
          </div>

          <p className="line" />

          <h3>Under 18 Applicant</h3>

          <p>
            If the applicant is not yet eighteen (18) years of age, the
            applicant’s parent/guardian must also complete and sign the
            following.
          </p>

          <div
            className="waiver"
            style={{ height: "auto", maxHeight: "175px" }}
          >
            <p>
              I, (name of Parent/Guardian entered below), hereby warrant that I
              am the (parent or guardian) of ({names.applicant}) a minor, and
              that I have full authority to authorize the above Release which I
              have read an approved. I hereby release and agree to indemnify the
              licensed parties and their respective successors and assigns, from
              and against any and all liability arising out of the rights
              granted by the above.
            </p>
          </div>

          <div className="one">
            <div>
              <Input
                type="text"
                label="Name of Parent/Guardian"
                req={false}
                name="signed2"
                value={names.signed2}
                update={handleUpdate}
                errors={errors}
              />
            </div>
          </div>

          <div className="msg-submit">
            {msg.type && <Msg data={msg} />}

            <div
              className={msg.type === "working" ? "hidden" : "submit-container"}
            >
              <Submit
                name="Submit Application to HCC"
                icon={faChevronCircleRight}
                cares={cares}
              />
            </div>
          </div>
          <p>
            We will not share your email address with third-parties. For more
            information on how we use your information, see our{" "}
            <a
              href="https://img.com/privacypolicy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            .
          </p>
          <p className="spacer" />
        </form>
      )}
    </>
  );
}

export default SubmitPage;
