import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

import { API_URL_DONATE } from "../../utils/helpers";

import Alert from "../../components/Alert";

const Receipt = ({ id }) => {
	const [order, setOrder] = useState({});
	const [alert, setAlert] = useState({
		type: "working",
		text: "",
	});

	useEffect(() => {
		window.scrollTo(0, 0);
		init();
	}, [id]);

	const init = async () => {
		const url = new URL(`${API_URL_DONATE}/`);
		const params = {
			f: "receiptInit",
			id,
			x: uuidv4(),
		};
		url.search = new URLSearchParams(params);

		try {
			const response = await fetch(url, {
				method: "GET",
				cache: "no-store",
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setOrder({
					id: json.id,
					date: json.date,
					items: json.items,
					totals: json.totals,
					text: json.text,
				});
				setAlert({});
			} else {
				setAlert({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setAlert({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	return (
		<>
			{alert.type ? (
				<Alert data={alert} />
			) : (
				<div className="receipt">
					<p>
						Thank you for your generous donation to Children’s Healthcare
						Charity.
					</p>

					<p>{order.text}</p>

					<table className="submitted">
						<tbody>
							<tr>
								<th className="right">Order Number:</th>
								<td>{order.id}</td>
							</tr>
							<tr>
								<th className="right">Date Submitted:</th>
								<td>{order.date}</td>
							</tr>
						</tbody>
					</table>
					<p className="line" />
					<table className="order">
						<thead>
							<tr>
								<th>Qty</th>
								<th className="left">Item</th>
								<th>Amount</th>
							</tr>
						</thead>
						<tbody>
							{order.items.map((item, index) => {
								return (
									<tr key={index}>
										<td className="center">
											<span>{item.qty}</span>
										</td>
										<td>
											<div>
												<span>{item.item}</span>
												<small>{item.subitem}</small>
											</div>
										</td>
										<td className="right">{item.price}</td>
									</tr>
								);
							})}
						</tbody>
						<tfoot>
							<tr>
								<td colSpan="2" className="right">
									TOTAL
								</td>
								<td className="right">{order.totals.total}</td>
							</tr>
							<tr>
								<td colSpan="2" className="right">
									{order.totals.paid}
								</td>
								<td className="right">{order.totals.amt}</td>
							</tr>
						</tfoot>
					</table>

					<p>
						100% of your donation amount of{" "}
						<strong>{order.totals.total}</strong> will support non-profit
						organizations in Palm Beach County and throughout South Florida
						through our annual grants program that work to enhance the health
						and welfare of our children and the future of our community.
					</p>

					<p>
						Please accept this acknowledgement as an official receipt in
						compliance with IRS regulations. Children’s Healthcare Charity is a
						501(c)(3) tax exempt organization with Federal ID #20-4394654.
					</p>

					<p>Thanks again for your support!</p>

					<p className="spacer" />
				</div>
			)}
		</>
	);
};

export default Receipt;
